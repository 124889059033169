<!-- DZ COMMENTS -->
<span id="{{sectionId}}" class="section-comments">
    <!-- <ng-container *ngIf="!info">
        <span class="header-tip" placement="bottom" ngbTooltip="Loading comments...">
            <i class="fa fa fa-spinner fa-pulse fa-fw"></i>
        </span>
    </ng-container>
    <ng-container *ngIf="info">
        <span class="comment-ico-wrapper" (click)="openDialog()">
            <i class="flaticon-chat-1" [ngClass]="{'lightblue': info.badge, 'grey': !info.badge}"></i>
            <span class="m-badge pulse-badge">{{info.badge}}</span>
        </span>
    </ng-container> -->
</span>