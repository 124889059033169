import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroyed } from './until-destroyed';

export class Store<State = Record<string, any>> extends UntilDestroyed {
    private store$: BehaviorSubject<State> = new BehaviorSubject<State>(
        this.initialState
    );

    constructor(private readonly initialState: State) {
        super();
    }

    /**
     * Select a specific key in the store.
     * @param key
     * @returns
     */
    protected select<T extends keyof State>(key: T): Observable<State[T]> {
        return this.store$.pipe(
            map((store) => store[key]),
            distinctUntilChanged()
        );
    }

    /**
     * Get the whole store object.
     * @returns
     */
    protected selectAll(): Observable<State> {
        return this.store$.pipe(distinctUntilChanged());
    }

    /**
     * update the state of the store.
     * @param functionOrPartial Can be a function with the current store state as argument or a partial of the state
     */
    protected dispatch(
        functionOrPartial: ((state: State) => Partial<State>) | Partial<State>
    ): void {
        const state = this.snapshot();
        if (typeof functionOrPartial === 'function') {
            this.store$.next({ ...state, ...functionOrPartial({ ...state }) });
        } else {
            this.store$.next({ ...state, ...functionOrPartial });
        }
    }

    /**
     * Get a snapshot of the current state of the store.
     * @returns
     */
    protected snapshot(): State {
        return this.store$.getValue();
    }
}
