<div class="avatar-container">
    <div
        class="image-container"
        *ngIf="file; else placeholder"
        [ngClass]="{ 'box-shadow-avatar': !!file }"
        [ngStyle]="{ backgroundImage: 'url(' + file + ')' }"
    ></div>

    <ng-template #placeholder>
        <div class="image-container avatar-placeholder"></div>
    </ng-template>
</div>
