import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../core/services/global.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./loading.component.scss'],
    imports: [NgIf]
})
export class LoadingComponent implements OnDestroy {
    public show: boolean = false;
    public text: string;
    private subscription: Subscription;
    constructor(private globalService: GlobalService) {
        this.subscription = globalService.loadingObservable.subscribe((data: any) => {
            if (data) {
                if (data.isObj) {
                    this.text = data.text;
                    this.show = data.show;
                } else {
                    this.text = null;
                    this.show = data;
                }
            } else {
                this.text = null;
                this.show = false;
            }
        });
    }
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
