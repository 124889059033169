import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'app/core/services/dashboard.service';
import { EntityService } from 'app/core/services/entity.service';
import { ServerService } from 'app/core/services/server.service';
import { abortUnsubscribe } from 'app/shared/utils/shared-methods.utils';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { PopupService } from '../popup.service';
import { UserService } from 'app/core/services/user.service';
import { GlobalService } from 'app/core/services/global.service';
import { DxFormModule } from 'devextreme-angular';
import { DxTemplateModule } from 'devextreme-angular/core';
import {
    DxiColumnModule,
    DxiItemModule,
    DxoEditingModule,
    DxoFilterRowModule,
    DxoFormItemModule,
    DxoPagerModule,
    DxoPagingModule,
    DxoSelectionModule
} from 'devextreme-angular/ui/nested';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';

@Component({
    selector: 'app-section-comments-dialog',
    templateUrl: './section-comments-dialog.component.html',
    imports: [
        DxDataGridModule,
        DxoFilterRowModule,
        DxoPagingModule,
        DxoPagerModule,
        DxoEditingModule,
        DxoSelectionModule,
        DxiColumnModule,
        DxoFormItemModule,
        DxTemplateModule,
        DxFormModule,
        DxiItemModule
    ]
})
export class SectionCommentsDialogComponent implements OnInit, OnDestroy {
    public section_id: string; // Filled at onOpen()
    public isManager: boolean;
    public dialogHeader = '';
    // Grid
    public gridDataSource: DataSource;
    private totalCount: number;
    private currentNode: any;
    private selectionTriggered: boolean;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        public activeModal: NgbActiveModal,
        private popupService: PopupService,
        private serverService: ServerService,
        private entityService: EntityService,
        private dashboardService: DashboardService,
        private userService: UserService,
        private globalService: GlobalService
    ) {}
    public ngOnInit(): void {
        this.popupService.onOpen();
        const _section = this.dashboardService.sections.filter(
            (node) => node.section_id === this.section_id
        )[0];
        this.dialogHeader =
            this.dashboardService.title + ' - ' + _section.name + ' Comments';
        this.isManager = this.userService.user.access_level > 1 ? true : false; // TODO verify access_level
        this.gridDataSource = new DataSource({
            load: this._onGridLoad.bind(this),
            totalCount: this._onGridTotalCount.bind(this),
            insert: this._onGridInsert.bind(this),
            remove: this._onGridRemove.bind(this)
        });
    }
    public onInitNewRow(ev: any): void {
        if (this.currentNode) {
            const _keys = ev.component.getSelectedRowKeys();
            ev.component.deselectRows(_keys);
        }
        ev.data.title = '';
        ev.data.date = new Date();
        ev.data.created_by =
            this.userService.user.first_name + ' ' + this.userService.user.last_name;
        ev.data.filters = this.entityService.selectedEntityLabel
            ? this.entityService.selectedEntityLabel.join(',')
            : '';
        if (
            this.dashboardService.options_title &&
            this.dashboardService.options_title.length
        ) {
            ev.data.filters += ' |' + this.dashboardService.options_title;
        }
    }
    public selectionChanged(ev: any): void {
        ev.component.collapseAll(-1);
        if (ev.currentSelectedRowKeys.length) {
            const _node = ev.currentSelectedRowKeys[0];
            this._expandNode(ev, _node);
            this.currentNode = _node;
            this.selectionTriggered = true;
        } else {
            this.currentNode = null;
        }
    }
    public onRowClick(ev: any): void {
        if (ev.rowType === 'data' && !this.selectionTriggered && this.currentNode) {
            const _keys = ev.component.getSelectedRowKeys();
            ev.component.deselectRows(_keys);
        }
        this.selectionTriggered = false;
    }
    private _expandNode(ev: any, node: any): void {
        ev.component.expandRow(node);
        if (!node.read) {
            node.read = true;
            this.serverService.post(
                'reader-comments-tool/update',
                {
                    id: node.id
                },
                (_data: any) => {
                    // Do something?
                },
                (_error) => {
                    console.error(_error);
                },
                undefined,
                true
            );
        }
    }
    /**
     * Method called twice by dxGrid
     */
    private _onGridLoad(loadOptions: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const _params: any = {
                dashboard_id: this.dashboardService.id,
                section_id: this.section_id
            };
            // Getting sort options
            if (loadOptions.sort) {
                _params.sort = JSON.stringify(loadOptions.sort);
            }
            // Getting filter settings
            if (loadOptions.filter) {
                _params.filter = JSON.stringify(loadOptions.filter);
            }
            // skip and take are used for paging
            _params.skip = loadOptions.skip ? loadOptions.skip : 0; // A number of records that should be skipped
            _params.take = loadOptions.take ? loadOptions.take : 10; // A number of records that should be taken
            // Call server side
            abortUnsubscribe(this.ngUnsubscribe);
            this.serverService.post(
                'reader-comments-tool/pageSection',
                _params,
                (result: any) => {
                    this.totalCount = result.totalCount;
                    resolve(result.data);
                },
                (_error) => {
                    // Do something else?
                    reject(true);
                },
                this.ngUnsubscribe,
                true
            );
        });
    }
    private _onGridTotalCount(_loadOptions: any): Promise<number> {
        return Promise.resolve(this.totalCount);
    }
    private _onGridInsert(node: any): Promise<any> {
        node.dashboard_id = this.dashboardService.id;
        node.section_id = this.section_id;
        return new Promise((resolve, reject) => {
            // Call server side
            this.serverService.post(
                'manager-comments-tool/insert',
                node,
                () => {
                    resolve(null);
                },
                (error) => {
                    // Do something else?
                    reject(error);
                },
                undefined,
                true
            );
        });
    }
    private _onGridRemove(node: any): Promise<void> {
        return new Promise((resolve, reject) => {
            if (
                this.userService.user.access_level < 2 &&
                this.userService.user.user_id !== node.user_creator
            ) {
                this.globalService.alert(
                    'Unauthorized',
                    'You are only allowed to delete comments made by yourself.'
                );
                resolve();
            } else {
                // Call server side
                this.serverService.post(
                    'manager-comments-tool/remove',
                    node,
                    () => {
                        resolve();
                    },
                    (error) => {
                        // Do something else?
                        reject(error);
                    },
                    undefined,
                    true
                );
            }
        });
    }
    public clear(): void {
        this.activeModal.dismiss('cancel');
    }
    public ngOnDestroy(): void {
        abortUnsubscribe(this.ngUnsubscribe, true);
    }
}
