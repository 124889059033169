import { Component, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class PopupService {
    private ngbModalRef: NgbModalRef;
    private _onOpen: (args?: any) => void;

    constructor(private modalService: NgbModal) {
        this.ngbModalRef = null;
    }

    public open(
        component: Component,
        onOpen?: (args?: any) => void,
        onClose?: (args?: any) => void,
        onDismiss?: (args?: any) => void,
        size: 'sm' | 'lg' | 'xl' = 'lg'
    ): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve /*, reject*/) => {
            if (this.ngbModalRef !== null) {
                resolve(this.ngbModalRef);
            }
            // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
                this._onOpen = onOpen;
                this._open(component, size, onClose, onDismiss);
                resolve(this.ngbModalRef);
            }, 0);
        });
    }

    public onOpen(): void {
        if (this._onOpen !== undefined) {
            this._onOpen(this.ngbModalRef.componentInstance);
        }
    }

    private _open(
        component: Component,
        size: 'sm' | 'lg' | 'xl',
        onClose?: (args?: any) => void,
        onDismiss?: (args?: any) => void
    ): void {
        this.ngbModalRef = this.modalService.open(component, {
            size: size,
            backdrop: 'static'
        });
        this.ngbModalRef.result.then(
            (result) => {
                if (onClose !== undefined) {
                    onClose(result);
                }
                this.destroy();
            },
            (result) => {
                if (onDismiss !== undefined) {
                    onDismiss(result);
                }
                this.destroy();
            }
        );
    }

    private destroy(): void {
        this.ngbModalRef = null;
        this._onOpen = undefined;
    }
}
