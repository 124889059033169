import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Add this directive at any input type="text" where only numbers are allowed
 */
@Directive({
    selector: '[numbers-only]',
    standalone: true
})
export class NumbersOnlyDirective {
    constructor(private elementRef: ElementRef) {}

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this.elementRef.nativeElement.value;
        this.elementRef.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
        if (initalValue !== this.elementRef.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
