import {
    ChangeDetectorRef,
    DestroyRef,
    Injectable,
    OnDestroy,
    inject
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UntilDestroyed implements OnDestroy {
    private readonly _isDestroyed$: Subject<void> = new Subject<void>();

    destroyRef: DestroyRef;

    constructor() {
        this.destroyRef = inject(DestroyRef);
    }

    protected get isDestroyed$(): Observable<void> {
        return this._isDestroyed$;
    }

    ngOnDestroy(): void {
        this._isDestroyed$.next();
        /* console.log('##### UntilDestroyed.ngOnDestroy()', this.isDestroyed$); */
    }
}

@Injectable()
export class UntilComponentDestroyed extends UntilDestroyed {
    protected readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
}
