import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'app/core/services/global.service';
import { ServerService } from 'app/core/services/server.service';
import { UserService } from 'app/core/services/user.service';
import { abortUnsubscribe } from 'app/shared/utils/shared-methods.utils';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { DxFormModule } from 'devextreme-angular';
import { DxTemplateModule } from 'devextreme-angular/core';
import {
    DxiColumnModule,
    DxiItemModule,
    DxoEditingModule,
    DxoFilterRowModule,
    DxoPagerModule,
    DxoPagingModule,
    DxoSelectionModule
} from 'devextreme-angular/ui/nested';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';

@Component({
    selector: 'app-comments-dialog',
    templateUrl: './comments-dialog.component.html',
    imports: [
        DxDataGridModule,
        DxoFilterRowModule,
        DxoPagingModule,
        DxoPagerModule,
        DxoEditingModule,
        DxoSelectionModule,
        DxiColumnModule,
        DxTemplateModule,
        DxFormModule,
        DxiItemModule
    ]
})
export class CommentsDialogComponent implements OnInit, OnDestroy {
    public isManager: boolean;
    // Grid
    public gridDataSource: DataSource;
    private totalCount: number;
    private currentNode: any;
    private selectionTriggered: boolean;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        public activeModal: NgbActiveModal,
        private serverService: ServerService,
        private userService: UserService,
        private globalService: GlobalService
    ) {}
    public ngOnInit(): void {
        this.isManager = this.userService.user.access_level > 1 ? true : false; // TODO verify access_level
        this.gridDataSource = new DataSource({
            load: this._onGridLoad.bind(this),
            totalCount: this._onGridTotalCount.bind(this),
            remove: this._onGridRemove.bind(this)
        });
    }
    public selectionChanged(ev: any): void {
        ev.component.collapseAll(-1);
        if (ev.currentSelectedRowKeys.length) {
            const _node = ev.currentSelectedRowKeys[0];
            this._expandNode(ev, _node);
            this.currentNode = _node;
            this.selectionTriggered = true;
        } else {
            this.currentNode = null;
        }
    }
    public onRowClick(ev: any): void {
        if (ev.rowType === 'data' && !this.selectionTriggered && this.currentNode) {
            const _keys = ev.component.getSelectedRowKeys();
            ev.component.deselectRows(_keys);
        }
        this.selectionTriggered = false;
    }
    public goToDashboard(ev: any): void {
        this.activeModal.close(this.currentNode);
    }
    private _expandNode(ev: any, node: any): void {
        ev.component.expandRow(node);
        if (!node.read) {
            node.read = true;
            this.serverService.post(
                'reader-comments-tool/update',
                {
                    id: node.id
                },
                (data: any) => {
                    // Do something?
                },
                (error) => {
                    console.error(error);
                },
                undefined,
                true
            );
        }
    }
    /**
     * Method called twice by dxGrid
     */
    private _onGridLoad(loadOptions: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const _params: any = {};
            // Getting sort options
            if (loadOptions.sort) {
                _params.sort = JSON.stringify(loadOptions.sort);
            }
            // Getting filter settings
            if (loadOptions.filter) {
                _params.filter = JSON.stringify(loadOptions.filter);
            }
            // skip and take are used for paging
            _params.skip = loadOptions.skip ? loadOptions.skip : 0; // A number of records that should be skipped
            _params.take = loadOptions.take ? loadOptions.take : 10; // A number of records that should be taken
            // Call server side
            abortUnsubscribe(this.ngUnsubscribe);
            this.serverService.post(
                'reader-comments-tool/page',
                _params,
                (result: any) => {
                    this.totalCount = result.totalCount;
                    resolve(result.data);
                },
                (error) => {
                    // Do something else?
                    reject(true);
                },
                this.ngUnsubscribe,
                true
            );
        });
    }
    private _onGridTotalCount(loadOptions: any): Promise<number> {
        return Promise.resolve(this.totalCount);
    }
    private _onGridRemove(node: any): Promise<void> {
        return new Promise((resolve, reject) => {
            if (
                this.userService.user.access_level < 2 &&
                this.userService.user.user_id !== node.user_creator
            ) {
                this.globalService.alert(
                    'Unauthorized',
                    'You are only allowed to delete comments made by yourself.'
                );
                resolve();
            } else {
                // Call server side
                this.serverService.post(
                    'manager-comments-tool/remove',
                    node,
                    () => {
                        resolve();
                    },
                    (error) => {
                        // Do something else?
                        reject(error);
                    },
                    undefined,
                    true
                );
            }
        });
    }
    public clear(): void {
        this.activeModal.dismiss('cancel');
    }
    public ngOnDestroy(): void {
        abortUnsubscribe(this.ngUnsubscribe, true);
    }
}
