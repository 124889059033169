import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-dashboard-options',
    templateUrl: './dashboard-options.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./dashboard-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass]
})
export class DashboardOptionsComponent implements AfterViewInit {
    @ViewChild('elt') el: ElementRef;

    @Input() public outsideCloseDisabled: boolean;

    show = false;
    constructor(private _eref: ElementRef) {}
    ngAfterViewInit(): void {
        $(this.el.nativeElement)
            .find('.dropdown-close')
            .click(() => {
                this.show = false;
            });
    }
    toggle(): void {
        this.show = !this.show;
    }
    @HostListener('document:click', ['$event'])
    clickedOutside($event): void {
        if (
            !this.outsideCloseDisabled &&
            !$event.DXCLICK_FIRED &&
            (!this._eref.nativeElement.contains($event.target) ||
                $event.target.className == 'btn btn-primary dropdown-toggle')
        ) {
            this.show = false;
        }
    }
}
