import { Component, OnInit, ViewEncapsulation, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from 'app/core/services/server.service';
import { GlobalService } from '../../core/services/global.service';
import { environment } from 'environments/environment';
@Component({
    selector: 'app-logout',
    templateUrl: './logout.page.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class LogoutPage implements OnInit {
    constructor(
        private _router: Router,
        private globalService: GlobalService,
        private serverService: ServerService
    ) {}
    ngOnInit(): void {
        this.globalService.loading(true);
        // reset login status
        this.serverService.post('access/logout', {}, (_data) => {
            if (environment.company === 'rhg' && !isDevMode()) {
                window.location.href =
                    'https://signon.radissonhotels.com/adfs/ls?ReturnTo=https://www.radissonconnects.com';
            }
            if (environment.company === 'lhg' && !isDevMode()) {
                window.location.href =
                    'https://login.microsoftonline.com/1eda050e-9e19-4f25-bf3d-090e18b9a70f/oauth2/v2.0/logout';
            }
        });
        localStorage.clear();
        if (isDevMode()) {
            this._router.navigate(['/login']);
        }
    }
}
