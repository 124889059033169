export const environment = {
    production: true,
    sentry_env: 'prod',
    name: 'prod',
    company: 'lhg',
    url: 'https://lhg.pulsebyrhg.com/',
    apiUrl: 'https://lhg.pulsebyrhg.com/api/',
    licenseKey:
        'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjc4OWY4YjIzLTZmMGUtNGQzYS05MTg2LTM3NzFiNDFhZTVmYiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQyCn0=.M9oFQSfggEiD44kmxuE4JRxytlrFcjdPOnrOEnZk4hJ2t4wqEpnBuozDc8gaXY2AyWR6icAYGHoa7tyPzkIJjT//LQeuZOFW4m27AFAhVLgq/xlacayW4ROvVtuw4Udz9irX5g==' // devexpress license key
};
