import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { SectionPrintData } from 'app/shared/utils/shared-constants.utils';
import * as echarts from 'echarts';
import { EChartOption } from 'echarts';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DashboardService } from './dashboard.service';
import { EntityService } from './entity.service';
import { GlobalService } from './global.service';
import moment from 'moment';
import { environment } from 'environments/environment';

type PDFMake = typeof import('pdfmake/build/pdfmake');

/**
 * Service used to create reports using pdfMake
 */
@Injectable({ providedIn: 'root' })
export class PdfMakeService {
    private renderer: Renderer2;
    private coverLogo: string;
    private headerLogo: string;
    private initPromise: Promise<any>;
    private dashboardFilters: string[];
    public company;

    private pdfMake: PDFMake;
    private fonts: { [file: string]: string };

    constructor(
        private globalService: GlobalService,
        private dashboardService: DashboardService,
        private entityService: EntityService,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.company = environment.company;

        let coverLogoUrl: string;
        let headerLogoUrl: string;

        if (this.company === 'lhg') {
            coverLogoUrl = 'assets/app/media/img/logos/PULSE_BY_LHG_LOGO_RGB.png';
            headerLogoUrl = 'assets/app/media/img/logos/PULSE_BY_LHG_LOGO_RGB.png';
        } else if (this.company === 'rhg') {
            coverLogoUrl =
                'assets/app/media/img/logos/PULSE_BY_THE_CLUB_RHG_LOGO_RGB.png';
            headerLogoUrl = 'assets/app/media/img/logos/new_pulse_logo_h85.png';
        }

        const _promiseCover = this._getBase64(coverLogoUrl).then((result) => {
            this.coverLogo = result;
        });
        const _promiseHeader = this._getBase64(headerLogoUrl).then((result) => {
            this.headerLogo = result;
        });
        this.initPromise = Promise.all([_promiseCover, _promiseHeader]);
    }
    async loadPDFMaker() {
        if (!this.pdfMake) {
            this.pdfMake = await import('pdfmake/build/pdfmake');
            this.fonts = (await import('pdfmake/build/vfs_fonts')).vfs;
        }
    }
    /**
     * Public method to create a pdf with an existing docDefinition and open it on a new tab
     * @param docDefinition
     */
    public openPdf(docDefinition: any): void {
        this.initPromise.then(() => {
            pdfMake.createPdf(docDefinition).open();
        });
    }
    /**
     * Public mehtod to create a pdf with an existing docDefinition and download it
     * @param docDefinition
     * @param docName
     */
    public downloadPdf(docDefinition: any, docName: string): void {
        this.initPromise.then(() => {
            pdfMake.createPdf(docDefinition).download(docName + '.pdf');
        });
    }
    /**
     * In case there are report-level filters that should be shown on every page.
     * Pass array of strings of what filters you want displayed. Call from the
     * update component of the dashboard component.
     * @param dashboardFilters
     */
    public setDashboardFilters(dashboardFilters: string[]): void {
        this.dashboardFilters = dashboardFilters;
    }
    /**
     * Call in NgOnDestroy.
     * Sets dashboardFilters variable to undefined when destroying component that set filters
     */
    public resetDashboardFilters(): void {
        this.dashboardFilters = undefined;
    }
    /**
     * Public method to create the dashboad pdf, this report contains:
     * - Cover page.
     * - One page for any node (section) obtained after dashboardCallback.
     * - Appendix.
     *
     * Each section page will include:
     * - The title of the section.
     * - The filters used that are not entities.
     * - The charts/tables of the section.
     * The appendix will show the entities used to fill the report.
     * @param dashboardCallback
     */
    public downloadDashboardPdf(dashboardCallback: () => any): void {
        this.globalService.loadingText('Generating PDF');
        setTimeout(() => {
            // Required to display loading popup
            this.initPromise.then(() => {
                dashboardCallback().then(
                    (sectionsPrintData: SectionPrintData[]) => {
                        let _dashboardOptions: string = this.entityService
                            .selectedEntityLabel
                            ? Array.isArray(this.entityService.selectedEntityLabel)
                                ? this.entityService.selectedEntityLabel.join(',')
                                : this.entityService.selectedEntityLabel
                            : '';
                        if (
                            this.dashboardService.options_title &&
                            this.dashboardService.options_title.length
                        ) {
                            _dashboardOptions +=
                                ' | ' + this.dashboardService.options_title;
                        }
                        const _today = moment().format('YYYY-MM-DD');
                        let _docDefinition: any;
                        if (this.dashboardFilters) {
                            _docDefinition = this._createDocDefinition(
                                _dashboardOptions,
                                _today,
                                this.dashboardFilters
                            );
                        } else {
                            _docDefinition = this._createDocDefinition(
                                _dashboardOptions,
                                _today
                            );
                        }
                        // Fill Cover
                        this._fillDashboardCover(
                            _docDefinition,
                            _dashboardOptions,
                            _today
                        );
                        for (const sectionPrintData of sectionsPrintData) {
                            if (
                                sectionPrintData.content &&
                                sectionPrintData.content.length
                            ) {
                                // Fill content of section page (also add page break)
                                // if (sectionPrintData)
                                sectionPrintData.content[0].pageBreak = 'before';
                                _docDefinition.content = _docDefinition.content.concat(
                                    sectionPrintData.content
                                );
                            }
                            if (sectionPrintData.styles) {
                                // Add styles / Override styles
                                for (const key in sectionPrintData.styles) {
                                    _docDefinition.styles[key] =
                                        sectionPrintData.styles[key];
                                }
                            }
                            // if (sectionPrintData.pageOrientation) {
                            //     _docDefinition.pageOrientation = sectionPrintData.pageOrientation;
                            // }
                        }
                        // Fill Appendix
                        this._fillDashboardAppendix(_docDefinition);
                        // Print
                        this.downloadPdf(_docDefinition, this.dashboardService.title);
                        this.globalService.loading(false);
                    },
                    (error) => {
                        console.error(error); // TODO
                        this.globalService.loading(false);
                        this.globalService.alert('Print error!', 'Unavailable to print!');
                    }
                );
            });
        }, 0);
    }
    /**
     * Public method to set the background-color at pdfMake tables
     * @param idx
     * @param node
     */
    public fillColorTable(idx: number, _node: any): string {
        if (idx) {
            return idx % 2 === 0 ? '#efefef' : null;
        } else {
            return '#d2dee6'; // header
        }
    }
    /**
     * Public method to set the background-color at pdfMake tables
     * (use this method if the table contains two rows of headers)
     * @param idx
     * @param node
     */
    public fillColorTable2(idx: number, _node: any): string {
        if (idx > 1) {
            return idx % 2 !== 0 ? '#efefef' : null;
        } else {
            return '#d2dee6'; // header
        }
    }

    /**
     * Public method to set the background-color at pdfMake tables
     * (use this method if the table contains two rows of headers)
     * @param idx
     * @param node
     */
    public fillColorTable3(idx: number, _node: any): string {
        if (idx > 2) {
            return idx % 2 !== 0 ? '#efefef' : null;
        } else {
            return '#d2dee6'; // header
        }
    }
    /**
     * Public method to turn echarts into images for pdf printing
     * @param echartOptions
     * @param sizes
     * @param callback
     * @param chartType
     * options:
     * 'default', '' (same as default),
     * 'pies' - nested pie chart,
     * 'topflop' - top and flop elements,
     * 'timeline' - big timeline with Y and X zoom,
     *  'custom' - no settings applied in function
     */
    public getEchartsDataURL(
        echartOptions: EChartOption[],
        sizes: any[],
        callback: (src: any[]) => void,
        chartType?: string[]
    ): void {
        const _divs = [];
        const _charts = [];
        const _srcs = [];
        for (let i = 0; i < echartOptions.length; i++) {
            // Create tmp echart
            const _div = this.renderer.createElement('div');
            const _chart = echarts.init(_div);
            _chart.resize({
                width: sizes[i].width,
                height: sizes[i].height,
                silent: false
            });
            echartOptions[i].animation = false;
            if (chartType) {
                switch (chartType[i]) {
                    case 'pies':
                        echartOptions[i].series[0].center = ['60%', '50%'];
                        echartOptions[i].series[1].center = ['60%', '50%'];
                        echartOptions[i].legend.textstyle = { fontSize: 6 };
                        echartOptions[i].legend.orient = 'vertical';
                        echartOptions[i].legend.left = 0;
                        echartOptions[i].legend.top = 30;
                        echartOptions[i].legend.itemHeight = 10;
                        echartOptions[i].legend.itemWidth = 15;
                        break;
                    case 'topflop':
                        echartOptions[i].grid = {
                            left: '10',
                            right: '25',
                            top: '3%',
                            bottom: '3%',
                            containLabel: true
                        };
                        if (echartOptions[i].title) {
                            echartOptions[i].title.show = false;
                        }
                        break;
                    case 'coaGeneric':
                        echartOptions[i].legend.top = 0;
                        echartOptions[i].legend.left = 'center';
                        echartOptions[i].legend.orient = 'horizontal';
                        break;
                    case 'timeline':
                        echartOptions[i].grid = {
                            left: '10',
                            right: '25',
                            bottom: '3%',
                            containLabel: true
                        };
                        echartOptions[i].dataZoom[0].show = false;
                        echartOptions[i].dataZoom[1].show = false;
                        break;
                    case 'custom':
                        break;
                    default:
                        echartOptions[i].grid = {
                            left: '10',
                            right: '25',
                            bottom: '3%',
                            containLabel: true
                        };
                        break;
                }
            } else {
                echartOptions[i].grid = {
                    left: '10',
                    right: '25',
                    bottom: '3%',
                    containLabel: true
                };
            }
            _chart.setOption(echartOptions[i]);
            _divs.push(_div);
            _charts.push(_chart);
        }
        setTimeout(() => {
            for (let e = 0; e < _charts.length; e++) {
                // Obtain echarts data url
                const _src = _charts[e].getDataURL({
                    pixelRatio: 2,
                    backgroundColor: '#fff',
                    excludeComponents: ['toolbox']
                });
                _srcs.push(_src);
                // Delete tmp echarts
                _charts[e].clear();
                _charts[e].dispose();
                _charts[e] = null;
                _divs[e] = null;
            }
            // Return src objects
            callback(_srcs);
        }, 3000);
    }
    private _createDocDefinition(
        dashboardOptions: string,
        today: string,
        dashboardFilters?
    ): any {
        return {
            pageMargins: [20, 80, 40, 60],
            pageSize: 'A4',
            header: (page: number, _pages: number): any => {
                return page === 1
                    ? {}
                    : this._createHeader(dashboardOptions, dashboardFilters);
            },
            footer: (page: number, pages: number): any => {
                return page === 1 ? {} : this._createFooter(today, page, pages);
            },
            content: [],
            styles: {
                // Default styles
                cover: { fontSize: 22, bold: true, margin: [0, 0, 0, 10] },
                header: { fontSize: 14, bold: true, margin: [0, 0, 0, 10] },
                headerWithoutMargin: { fontSize: 14, bold: true, margin: [0, 0, 0, 0] },
                subheader: { fontSize: 12, bold: true, margin: [0, 0, 0, 5] },
                textjust: { fontSize: 9, alignment: 'justify' },
                gray: { color: '#b1b5c1' },
                lightblue: { color: '#7E94AC' }
            }
        };
    }
    private _createHeader(dashboardOptions: string, dashboardFilters?: string[]): any {
        const _filterText: any[] = [];
        if (dashboardFilters) {
            for (let i = 0; i < dashboardFilters.length; i++) {
                _filterText.push({
                    text: dashboardFilters[i],
                    fontSize: 7,
                    margin: [50, i === 0 ? 15 : 0, 0, 0]
                });
            }
        }
        return {
            columns: [
                {
                    image: this.headerLogo,
                    width: 80,
                    height: 40,
                    alignment: 'left',
                    margin: [20, 15, 0, 0]
                },
                _filterText,
                {
                    text: [
                        { text: this.dashboardService.title },
                        ' ',
                        { text: dashboardOptions, style: 'lightblue' }
                    ],
                    alignment: 'right',
                    margin: [0, 25, 40, 0]
                }
            ]
        };
    }
    private _createFooter(today: string, page: number, pages: number): any {
        return {
            fontSize: 8,
            columns: [
                {
                    text: today + ' © Pulse by RHG',
                    style: 'gray'
                },
                {
                    text: [
                        { text: page.toString(), italics: true },
                        ' / ',
                        { text: pages.toString(), italics: true }
                    ],
                    alignment: 'right',
                    style: 'gray'
                }
            ],
            margin: [20, 30, 40, 0]
        };
    }
    private _fillDashboardCover(
        docDefinition: any,
        dashboardOptions: string,
        today: string
    ): void {
        const _cover = [
            {
                image: this.coverLogo,
                width: 480,
                height: 240,
                alignment: 'center',
                margin: [0, 60, 0, 60]
            },
            {
                text: this.dashboardService.title,
                style: ['cover', 'lightblue'],
                alignment: 'right'
            },
            { text: dashboardOptions, style: 'header', alignment: 'right' },
            { text: today, style: 'subheader', alignment: 'right' }
        ];
        docDefinition.content = docDefinition.content.concat(_cover);
    }
    private _fillDashboardAppendix(docDefinition: any): void {
        const _entities: string[] = this.entityService.getSelectedIds();
        const _appendix = [
            {
                text: 'Appendix',
                style: ['header', 'lightblue'],
                pageBreak: 'before',
                pageOrientation: 'portrait'
            },
            { text: 'List of hotels selected for this report:', style: 'subheader' },
            { text: _entities.join(', '), style: 'textjust' }
        ];
        docDefinition.content = docDefinition.content.concat(_appendix);
    }
    private async _getBase64(path: string): Promise<any> {
        const _res = await fetch(path);
        const _blob = await _res.blob();
        return new Promise((resolve, reject) => {
            const _reader = new FileReader();
            _reader.addEventListener(
                'load',
                () => {
                    resolve(_reader.result);
                },
                false
            );
            _reader.onerror = (): void => {
                reject(this);
            };
            _reader.readAsDataURL(_blob);
        });
    }
}
