import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Helpers } from '../../helpers';

@Directive({
    selector: '[appunwraptag]',
    standalone: true
})
export class UnwrapTagDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}
    ngAfterViewInit() {
        const nativeElement: HTMLElement = this.el.nativeElement;
        //$(nativeElement).removeAttr('appunwraptag').unwrap();
        Helpers.unwrapTag(nativeElement);
    }
}
