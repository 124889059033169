<div class="p-dropdown" [ngClass]="{ 'p-dropdown--open': show === true }" #elt>
    <span class="p-dropdown-toggle" (click)="toggle()">
        <ng-content select=".button"></ng-content>
    </span>
    <div class="p-dropdown-wrapper">
        <div class="p-dropdown-inner">
            <div class="p-dropdown-inner-container">
                <div>
                    <div class="m--pull-right">
                        <span id="optionsButtonToggle" class="close-ico" (click)="toggle()">
                            <i class="la la-4x la-remove"></i>
                        </span>
                    </div>
                </div>
                <ng-content select=".dropdown-content"></ng-content>
            </div>
            <span class="p-dropdown-arrow"></span>
        </div>
    </div>
</div>