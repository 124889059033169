<div class="modal-header">
    <h4 class="modal-title">{{dialogHeader}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()">&times;</button>
</div>
<div class="modal-body dx-pseudo-disabled">
    <div class="col-12">
        <div class="row g-0">
            <dx-data-grid #grid id="gridContainer" [dataSource]="gridDataSource" [remoteOperations]="true"
                [showBorders]="true" noDataText="No Comments" (onInitNewRow)="onInitNewRow($event)"
                (onSelectionChanged)="selectionChanged($event)" (onRowClick)="onRowClick($event)"
                [masterDetail]="{ enabled: false, template: 'masterDetail' }">
                <!-- parameters -->
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true"></dxo-pager>
                <dxo-editing mode="form" [allowUpdating]="false" [allowDeleting]="isManager" [allowAdding]="isManager">
                </dxo-editing>
                <dxo-selection mode="single"></dxo-selection>
                <!-- columns and form -->
                <dxi-column dataField="read" dataType="boolean" [width]="75">
                    <dxo-form-item [visible]="false">
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="title" dataType="string">
                    <dxo-form-item [colSpan]="2" [editorOptions]="{ maxLength: 100 }">
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="created_by" dataType="string">
                    <dxo-form-item [editorOptions]="{ disabled: true }">
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="date" dataType="date" [width]="125">
                    <dxo-form-item editorType="dxDateBox" [editorOptions]="{ disabled: true }">
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="filters" dataType="string" [visible]="false">
                    <dxo-form-item [colSpan]="2" editorType="dxTextArea"
                        [editorOptions]="{ height: 60, maxLength: 500 }">
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="comment" dataType="string" [visible]="false">
                    <dxo-form-item [colSpan]="2" editorType="dxTextArea"
                        [editorOptions]="{ height: 120, maxLength: 1000 }">
                    </dxo-form-item>
                </dxi-column>
                <!-- master detail -->
                <div *dxTemplate="let masterDetailItem of 'masterDetail'">
                    <dx-form [formData]="masterDetailItem.data" [colCount]="2">
                        <dxi-item dataField="title" dataType="string" [colSpan]="2"
                            [editorOptions]="{ disabled: true }"></dxi-item>
                        <dxi-item dataField="date" dataType="date" editorType="dxDateBox"
                            [editorOptions]="{ disabled: true }">
                        </dxi-item>
                        <dxi-item dataField="created_by" dataType="string" [editorOptions]="{ disabled: true }">
                        </dxi-item>
                        <dxi-item dataField="filters" dataType="string" [colSpan]="2" editorType="dxTextArea"
                            [editorOptions]="{ height: 60, maxLength: 500, disabled: true }"></dxi-item>
                        <dxi-item dataField="comment" dataType="string" [colSpan]="2" editorType="dxTextArea"
                            [editorOptions]="{ height: 120, maxLength: 1000, disabled: true }"></dxi-item>
                        <div *dxTemplate="let item of 'formItem'">
                            <span>{{item.editorOptions.value}}</span>
                        </div>
                    </dx-form>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>