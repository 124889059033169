import moment from 'moment';

export class TreeNode {
    code: string;
    label: string;
    level: number;
    expandable: boolean;
    children: TreeNode[];
    parentid: string;
}

export class TreeFlatNode {
    code: string;
    expandable: boolean;
    label: string;
    level: number;
    parentid: string;
    children: any[];
}

export class ffn {
    public static flatternByChildren(data, output): any[] {
        for (const n in data) {
            if (typeof data[n] == 'object') {
                if (data[n] && data[n].children && data[n].children.length) {
                    output.push(data[n]);
                    this.flatternByChildren(data[n].children, output);
                } else {
                    output.push(data[n]);
                }
            }
        }
        return output;
    }
    public static groupAndSum(arr, groupKeys, sumKeys, formulas?: any[]): any[] {
        const results = [];

        arr.forEach((curr) => {
            const group = groupKeys.map((k) => curr[k]).join('-');

            const existingGroup = results.find((item) => item.groupId === group);
            if (existingGroup) {
                sumKeys.forEach((k) => (existingGroup[k] += this.nOn(curr[k])));
            } else {
                const groupObj = { groupId: group };
                groupKeys.forEach((k) => (groupObj[k] = curr[k]));
                sumKeys.forEach((k) => (groupObj[k] = this.nOn(curr[k])));
                results.push(groupObj);
            }

            if (formulas) {
                formulas.forEach((e) => {
                    const [resultKey, expression] = Object.entries(e)[0];
                    results.forEach((m) => {
                        const evaluatedExpression = (expression as string).replace(
                            /([a-zA-Z_]\w*)/g,
                            (match, p1) =>
                                m[p1]
                                    ? `(${m[p1]})`
                                    : this.isNumber(p1) || p1 === 'ffn.nOn'
                                      ? p1
                                      : 0
                        );
                        try {
                            m[resultKey] = this.nOn(
                                Function(
                                    '"use strict";return (' + evaluatedExpression + ')'
                                )()
                            );
                        } catch (error) {
                            console.error(
                                'Error evaluating expression:',
                                evaluatedExpression,
                                error
                            );
                            m[resultKey] = 0;
                        }
                    });
                });
            }
        });

        return results;
    }

    public static groupAndAvg(arr, groupKeys, avgKeys, formulas?: any[]): any[] {
        const results = [];

        arr.forEach((curr) => {
            const group = groupKeys.map((k) => curr[k]).join('-');

            const existingGroup = results.find((item) => item.groupId === group);
            if (existingGroup) {
                avgKeys.forEach((k) => {
                    existingGroup[k] =
                        (existingGroup[k] * existingGroup.count + this.nOn(curr[k])) /
                        (existingGroup.count + 1);
                });
                existingGroup.count += 1;
            } else {
                const groupObj = { groupId: group, count: 1 };
                groupKeys.forEach((k) => (groupObj[k] = curr[k]));
                avgKeys.forEach((k) => (groupObj[k] = this.nOn(curr[k])));
                results.push(groupObj);
            }

            if (formulas) {
                formulas.forEach((e) => {
                    const [resultKey, expression] = Object.entries(e)[0];
                    results.forEach((m) => {
                        const evaluatedExpression = (expression as string).replace(
                            /([a-zA-Z_]\w*)/g,
                            (match, p1) =>
                                m[p1]
                                    ? `(${m[p1]})`
                                    : this.isNumber(p1) || p1 === 'ffn.nOn'
                                      ? p1
                                      : 0
                        );
                        try {
                            m[resultKey] = this.nOn(
                                Function(
                                    '"use strict";return (' + evaluatedExpression + ')'
                                )()
                            );
                        } catch (error) {
                            console.error(
                                'Error evaluating expression:',
                                evaluatedExpression,
                                error
                            );
                            m[resultKey] = 0;
                        }
                    });
                });
            }
        });

        results.forEach((group) => {
            delete group.count;
        });

        return results;
    }

    public static magicGrouping(
        arr,
        groupKeys,
        sumKeys,
        avgKeys,
        formulas?: any[]
    ): any[] {
        const results = [];

        arr.forEach((curr) => {
            const group = groupKeys.map((k) => curr[k]).join('-');

            const existingGroup = results.find((item) => item.groupId === group);
            if (existingGroup) {
                sumKeys.forEach((k) => (existingGroup[k] += this.nOn(curr[k])));
                avgKeys.forEach((k) => (existingGroup[k] += this.nOn(curr[k])));
                existingGroup.count += 1;
            } else {
                const groupObj: any = { groupId: group };
                groupKeys.forEach((k) => (groupObj[k] = curr[k]));
                sumKeys.forEach((k) => (groupObj[k] = this.nOn(curr[k])));
                avgKeys.forEach((k) => (groupObj[k] = this.nOn(curr[k])));
                groupObj.count = 1;
                results.push(groupObj);
            }
        });

        // Calculate averages for each group
        results.forEach((group) => {
            avgKeys.forEach((k) => {
                group[k] /= group.count;
                group[k] = this.nOn(group[k]);
            });
            delete group.count;
        });

        if (formulas) {
            results.forEach((group) => {
                formulas.forEach((e) => {
                    const [resultKey, expression] = Object.entries(e)[0];
                    let evaluatedExpression = (expression as string).replace(
                        /([a-zA-Z_]\w*)/g,
                        (match, p1) =>
                            group[p1]
                                ? `(${group[p1]})`
                                : this.isNumber(p1) || p1 === 'ffn.nOn'
                                  ? p1
                                  : 0
                    );
                    if (avgKeys.some((k) => evaluatedExpression.includes(k))) {
                        avgKeys.forEach((k) => {
                            evaluatedExpression = evaluatedExpression.replace(
                                new RegExp(`(${k})`, 'g'),
                                `(${group[k]})`
                            );
                        });
                    }
                    try {
                        group[resultKey] = this.nOn(
                            Function(
                                '"use strict";return (' + evaluatedExpression + ')'
                            )()
                        );
                    } catch (error) {
                        console.error(
                            'Error evaluating expression:',
                            evaluatedExpression,
                            error
                        );
                        group[resultKey] = 0;
                    }
                });
            });
        }

        return results;
    }

    public static mergeById(array1, array2, id, sortid): any[] {
        return array1
            .map((itm) => ({
                ...array2.find((item) => item[id] === itm[id] && item),
                ...itm
            }))
            .sort((a, b) => (a[sortid] < b[sortid] ? -1 : 1));
    }
    public static mergeByIdNoSort(array1, array2, id): any[] {
        return array1.map((itm) => ({
            ...array2.find((item) => item[id] === itm[id] && item),
            ...itm
        }));
    }

    public static mergePivot(array1, array2, array3, sortid): any[] {
        return array1
            .map((itm) => ({
                ...array2.find(
                    (item) =>
                        item.id === itm.id &&
                        item.CUSTOMACCOUNT === itm.CUSTOMACCOUNT &&
                        item
                ),
                ...array3.find(
                    (item) =>
                        item.id === itm.id &&
                        item.CUSTOMACCOUNT === itm.CUSTOMACCOUNT &&
                        item
                ),
                ...itm
            }))
            .sort((a, b) => (a[sortid] < b[sortid] ? -1 : 1));
    }
    public static merge3ById(array1, array2, array3, id, sortid): any[] {
        return array1
            .map((itm) => ({
                ...array2.find((item) => item[id] === itm[id] && item),
                ...array3.find((item) => item[id] === itm[id] && item),
                ...itm
            }))
            .sort((a, b) => (a[sortid] < b[sortid] ? -1 : 1));
    }

    public static nOn(value): number {
        return !isNaN(value) && isFinite(value) && value != null ? value : 0;
    }

    public static formatEmptyDate(value: string, replacementValue = ''): string {
        return value === '1970-01-01' ? replacementValue : value;
    }

    public static exist(item: any): any {
        return item ? true : false;
    }

    public static getData(
        data,
        transposeID,
        filterBy,
        filterVal,
        sumKeys,
        groupKeys,
        calculatedKeys?
    ): any[] {
        // ajout de la reference colonne de transposition
        let transposeField = '';
        if (transposeID.field) {
            transposeField = transposeID.field;
            groupKeys.push(transposeField);
        }

        filterBy != '' ? groupKeys.push(filterBy) : null;

        // creation data set sur base des colonnes d'agregation et des colonnes a agreger.
        const dataset = this.groupAndSum(data, groupKeys, sumKeys);

        if (transposeField == '') {
            if (filterBy != '') {
                return dataset.filter((f) => filterVal.includes(f[filterBy]));
            } else {
                return dataset;
            }
        }

        if (calculatedKeys && calculatedKeys.length > 0) {
            calculatedKeys.forEach((e) => {
                if (
                    ['/', '(', ')', '*', '+', '-', '', ' '].some(
                        e.formula.includes.bind(e.formula)
                    )
                ) {
                    dataset.map((m) => {
                        const formula = e.formula
                            .split(/([*+\/\-)(])/)
                            .map((frl: string) => {
                                return !['/', '(', ')', '*', '+', '-', ''].includes(frl)
                                    ? m[frl]
                                        ? '(' + m[frl] + ')'
                                        : this.isNumber(frl) || frl == 'ffn.nOn'
                                          ? frl.replace('ffn.nOn', 'this.nOn')
                                          : 0
                                    : frl;
                            })
                            .join('');
                        try {
                            m[e.field] = new Function('return ' + formula).call(this);
                        } catch (error) {
                            console.error('Error evaluating formula:', formula, error);
                            m[e.field] = 0;
                        }
                    });

                    sumKeys = sumKeys.concat([e.field]);
                }
            });
        }
        // remove transpose column

        groupKeys = groupKeys.filter(function (e) {
            return e !== transposeField;
        });
        // concat column d'aggregation pour IDX
        dataset.map((m) => {
            m.IDX = groupKeys.map((k) => m[k]).join('-');
        });

        //ajout du IDX dans les colonnes d'aggregation
        groupKeys.push('IDX');

        // constante pour recherche distinct group d'agregation
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const uniqueBy = (x, f) =>
            Object.values(x.reduce((a, b) => ((a[f(b)] = b), a), {}));
        // setting variable des valeurs d'aggregation distinctes
        const distinctItem: any[] = uniqueBy(
            dataset.map((m) =>
                (<any>Object).fromEntries(groupKeys.map((item) => [item, m[item]]))
            ),
            (v) => v.IDX
        );

        const result: any[] = [];
        distinctItem.forEach((element) => {
            sumKeys.forEach((sKey) => {
                let obj: any = (<any>Object).fromEntries(
                    dataset
                        .filter((f) => f.IDX == element.IDX)
                        .map((item) => [
                            transposeID.ref + item[transposeField],
                            isNaN(item[sKey]) ? 0 : item[sKey]
                        ])
                );
                ////console.log('Obj',fieldSplit, obj)
                groupKeys.map((k) => (obj[k] = element[k]));
                const groupRef =
                    calculatedKeys &&
                    calculatedKeys.length > 0 &&
                    calculatedKeys.find((el) => el.field === sKey)
                        ? calculatedKeys.find((el) => el.field === sKey).groupRef
                        : '';
                const optionalLabel =
                    calculatedKeys &&
                    calculatedKeys.length > 0 &&
                    calculatedKeys.find((el) => el.field === sKey)
                        ? calculatedKeys.find((el) => el.field === sKey).label
                        : '';
                obj = Object.assign(
                    { KPI: sKey, groupRef: groupRef, label: optionalLabel },
                    obj
                );

                result.push(obj);
            });
        });

        if (filterBy != '') {
            return result.filter((f) => filterVal.includes(f[filterBy]));
        } else {
            return result;
        }
    }

    public static getUniqueItems(dataset, field): any[] {
        return Array.from(new Set(dataset.map((m) => m[field])));
    }
    public static getUniqueObjects(dataset, field): any[] {
        return Array.from(new Set(dataset.map((m) => m[field])));
    }

    public static getRevPercent(report, data, retrieveid, field): number {
        const revPercent = report.filter((rf) => rf.RETRIEVEID == retrieveid)[0]
            ? report.filter((rf) => rf.RETRIEVEID == retrieveid)[0].REVPERCENT
            : '';

        let result = 0;
        if (revPercent != '') {
            result = data.filter((f) => f.KPI == revPercent)[0]
                ? data.filter((f) => f.KPI == revPercent)[0][field]
                : 0;
        }
        return this.nOn(result);
    }

    public static getRevPercount(report, data, retrieveid, field): number {
        const revPercount = report.filter((rf) => rf.RETRIEVEID == retrieveid)[0]
            ? report.filter((rf) => rf.RETRIEVEID == retrieveid)[0].DATAPERCOUNT
            : '';
        let result = 0;
        if (revPercount != '') {
            result = data.filter((f) => f.KPI == revPercount)[0]
                ? data.filter((f) => f.KPI == revPercount)[0][field]
                : 0;
        }

        return this.nOn(result);
    }

    public static nFormatter(num, digits, format?): string {
        if (typeof num === 'string') {
            num = num.replace(/,/, '');
        }
        num =
            (format && format == 'PERCENT') ||
            (format && format == 'PERCENTPOINTS') ||
            (format && format == 'PERCENTLESS')
                ? num * 100
                : num;
        const sign = num >= 0 ? '' : '-';

        num = Math.abs(num);
        const si = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'B' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        let result = '';
        if (format && format == 'STANDARD') {
            result =
                sign +
                num
                    .toLocaleString(undefined, {
                        minimumFractionDigits: digits,
                        maximumFractionDigits: digits
                    })
                    .replace(rx, '$1');
        } else {
            result =
                sign +
                (num / si[i].value).toFixed(digits).replace(rx, '$1') +
                si[i].symbol;
        }

        //return result;

        return num == 0
            ? '-'
            : format && format == 'PERCENT'
              ? result + '%'
              : format && format == 'PERCENT'
                ? result
                : format && format == 'PERCENTPOINTS'
                  ? result + ' pts'
                  : result;
    }

    public static isNumber(o): boolean {
        return !isNaN(o - 0) && o !== null && o !== '' && o !== false;
    }
    public static getVar(A, B): number {
        return isNaN(A - B) ? 0 : A - B;
    }

    public static dateToShortFormat(date): string {
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];
        const year = date.getFullYear();
        return `${day}-${monthName}-${year}`;
    }
    public static dateToDayFormat(date): string {
        const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const day = date.getDate();
        const dayIndex = date.getDay();
        const dayName = dayNames[dayIndex];
        return `${day} ${dayName}`;
    }
    public static dateToFullMonthYearFormat(date): string {
        return moment(new Date(date)).format('MMMM-YYYY');
    }

    public static colorSheme(id): string {
        const colors: any[] = ['#7e93ac', '#b58802']; // Radisson  theme
        return colors[id];
    }

    public static getGrowth(A, B): number {
        const result =
            this.nOn(A) != 0 && this.nOn(B) == 0
                ? 1
                : (this.nOn(A) - this.nOn(B)) / Math.abs(this.nOn(B));

        return this.nOn(result * 100);
    }

    public static sumUp(object, field: string): any[] {
        object[field] =
            object.children.length > 0 && object.coverinput != 1 ? 0 : object[field];
        object.children.forEach((el) => {
            object[field] += this.sumUp(el, field);
        });
        {
        }
        return object[field];
    }

    public static sumUpFields(object, fields: any[] = ['rns', 'rev']): void {
        //console.log('sumUpFields', fields)
        fields.forEach((f) => {
            object[f] = object.children.length > 0 ? 0 : object[f];
            object.children.forEach((el) => {
                object[f] += this.sumUp(el, f);
            });
            return object[f];
        });
    }

    public static searchTree(tree, nodesProp, prop, value): any {
        let i,
            f = null; // iterator, found node
        if (Array.isArray(tree)) {
            // if entry object is array objects, check each object

            for (i = 0; i < tree.length; i++) {
                f = this.searchTree(tree[i], nodesProp, prop, value);
                if (f) {
                    // if found matching object, return it.
                    return f;
                }
            }
        } else if (typeof tree === 'object') {
            // standard tree node (one root)
            if (tree[prop] !== undefined && tree[prop] === value) {
                return tree; // found matching node
            }
        }
        if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
            // if this is not maching node, search nodes, children (if prop exist and it is not empty)
            return this.searchTree(tree[nodesProp], nodesProp, prop, value);
        } else {
            return null; // node does not match and it neither have children
        }
    }

    // to build table tree preliminary hierarchy
    public static buildHierarchy(
        ddata: any[],
        levelFields: any[],
        _sumUpFields: any[],
        _extraRef?: any[]
    ): any[] {
        const lvlFields = levelFields; //['DI', 'CH', 'SCH']
        ddata.map((m) => {
            const mergedFieldValue: any[] = [];
            levelFields.forEach((l) => {
                mergedFieldValue.push(m[l]);
            });
            m.code = mergedFieldValue.join('#');
        });
        const unique = Array.from(new Set(ddata.map((item) => item.code))).map((m) => ({
            code: m,
            level: levelFields.length,
            parentid: m.split('#').slice(0, -1).join('#')
        }));
        let treehierarchy: any[] = [...unique].map((item) => ({ ...item }));
        for (let lvl = lvlFields.length - 1; lvl >= 1; lvl--) {
            const previousLevel: any[] = treehierarchy
                .filter((f) => f && f.level == lvl + 1)
                .map((item) => ({ ...item }));
            const levelData = previousLevel.map((m) => ({
                code: m.code.split('#').slice(0, -1).join('#'),
                level: lvl,
                parentid: m.code.split('#').slice(0, -2).join('#')
            }));
            treehierarchy = treehierarchy.concat(levelData);
        }
        const ht: any[] = Array.from(new Set(treehierarchy.map((m) => m.code))).map(
            (m) => ({
                code: m,
                label: m.split('#')[m.split('#').length - 1],
                parentid:
                    m.split('#').slice(0, -1).join('#') == ''
                        ? 'total'
                        : m.split('#').slice(0, -1).join('#')
            })
        );
        //console.log('ht', ht);
        return ht;
        //return ffn.buildTableData(ddata, ht, sumUpFields, extraRef);
    }
    public static buildMatTableTree(
        data,
        options,
        fields,
        parentLevel,
        extraRef?
    ): TreeNode[] {
        options = options || {};
        const ID_KEY = options.idKey || 'code';
        const PARENT_KEY = 'parentid';
        const CHILDREN_KEY = options.childrenKey || 'children';

        const tree: any[] = [],
            childrenOf = {};
        let item: any, id, parentId;

        for (let i = 0, length = data.length; i < length; i++) {
            item = {};
            const element = data[i];
            item = {
                code: element.code,
                label: element.label,
                parentid: element.parentid
            };

            // set Extra Fields
            fields.concat(extraRef).forEach((f) => {
                item[f] = element.hasOwnProperty(f) ? element[f] : 0;
            });
            id = item[ID_KEY];
            parentId = item[PARENT_KEY] || '';

            // every item may have children
            childrenOf[id] = childrenOf[id] || [];

            // init its children

            item[CHILDREN_KEY] = childrenOf[id];

            if (parentId != parentLevel) {
                // init its parent's children object
                childrenOf[parentId] = childrenOf[parentId] || [];
                // push it into its parent's children object
                childrenOf[parentId].push(item);
            } else {
                tree.push(item);
            }
        }
        //console.log('buildTrialBalanceTree...', tree)
        return tree;
    }
    public static buildTableData(
        ddata,
        hierarchy,
        sumUpFields: any[],
        extraRef?: any[]
    ): any[] {
        let smrdata = ffn.groupAndSum(ddata, ['code'].concat(extraRef), sumUpFields);

        smrdata = ffn.mergeById(hierarchy, smrdata, 'code', 'code');
        smrdata = smrdata.sort((a, b) => (a.orderid < b.orderid ? -1 : 1));

        const treeTop = [{ code: 'total', label: 'Total', parentid: 'top' }];

        const treeData = ffn.buildMatTableTree(
            [...treeTop, ...smrdata],
            {
                idKey: 'code',
                parentKey: 'parentid',
                childrenKey: 'children'
            },
            sumUpFields,
            'top',
            extraRef
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const totalofall = ffn.sumUpFields(treeData[0], sumUpFields);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const totalnode = ffn.searchTree(treeData, 'children', 'code', 'total');

        return treeData;
    }
    public static buildTableDataWithoutSum(
        ddata,
        hierarchy,
        sumUpFields: any[],
        extraRef?: any[]
    ): any[] {
        let smrdata = ffn.groupAndSum(ddata, ['code'].concat(extraRef), sumUpFields);

        smrdata = ffn.mergeById(hierarchy, smrdata, 'code', 'code');
        smrdata = smrdata.sort((a, b) => (a.orderid < b.orderid ? -1 : 1));

        const treeTop = [{ code: 'total', label: 'Total', parentid: 'top' }];

        const treeData = ffn.buildMatTableTree(
            [...treeTop, ...smrdata],
            {
                idKey: 'code',
                parentKey: 'parentid',
                childrenKey: 'children'
            },
            sumUpFields,
            'top',
            extraRef
        );

        return treeData;
    }
    public static getDistinctDays(monthid, yearid): any[] {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const daysInMonth = moment(new Date(monthid + '-01-' + yearid)).daysInMonth();
        const distinctDays = [];
        for (let dayid = 1; dayid <= daysInMonth; dayid++) {
            distinctDays.push({
                dayid: dayid,
                dof: days[new Date(monthid + '-' + dayid + '-' + yearid).getDay()],
                daylabel:
                    dayid +
                    '\n' +
                    days[new Date(monthid + '-' + dayid + '-' + yearid).getDay()]
            });
        }
        return distinctDays;
    }

    public static getDistinctDaysRange(
        startdate: string | Date,
        enddate: string | Date
    ): any[] {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const distinctDays = [];
        const startDate = new Date(startdate);
        const endDate = new Date(enddate);
        for (
            let dayid = new Date(startDate);
            dayid.toISOString().substring(0, 10) <=
            endDate.toISOString().substring(0, 10);
            dayid.setDate(dayid.getDate() + 1)
        ) {
            const year = dayid.getFullYear();
            const month = dayid.getMonth() + 1; // Months are 0-based, so add 1
            const day = dayid.getDate();
            distinctDays.push({
                date: `${year}-${month}-${day}`,
                dayid: dayid.getDate(),
                dof: days[dayid.getDay()],
                daylabel: dayid.getDate() + '\n' + days[dayid.getDay()]
            });
        }
        return distinctDays;
    }

    public static getDistinctMonths(yearid, limit?: number): any[] {
        const monthlimit = limit ? limit + 1 : 13;
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const distinctMonths = [];
        for (let monthid = 1; monthid < monthlimit; monthid++) {
            distinctMonths.push({
                monthid: monthid,
                mof: months[monthid - 1],
                monthlabel: months[monthid - 1] + '\n' + yearid
            });
        }
        return distinctMonths;
    }

    public static formatTableData(treeNodes, columns): void {
        for (const treeNode of treeNodes) {
            columns.forEach((c) => {
                if (treeNode[c.field]) {
                    treeNode[c.field] = this.getTdResults(c, treeNode);
                }
            });
            if (treeNode.children != null)
                this.formatTableData(treeNode.children, columns);
        }
    }

    public static getTdResults(
        column: { type: string; field: string | number; format: string; formula? },
        element: { [x: string]: string; itemType: string; format: any }
    ): string {
        if (column.formula && column.formula != '') {
            const formula = column.formula.replace(/\[/g, 'element[');
            element[column.field] = new Function('element', `return ${formula}`)(element);
        }
        if (column.field == 'label') {
            return element[column.field];
        }
        if (column && column.type == 'str') {
            return element[column.field];
        }

        if (
            (element && element.itemType == 'nbr') ||
            (element && element.itemType == 'growth')
        ) {
            switch (element.format) {
                case 'percent':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 1, 'PERCENT');
                case 'fixed':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 1);
                case 'fixed2':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 2);
                case 'standard':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 0, 'STANDARD');
                case 'standard1':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 1, 'STANDARD1');
                default:
                    return element[column.field];
            }
        }
        if (column && column.type == 'nbr' && !element.itemType) {
            switch (column.format) {
                case 'percent':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 1, 'PERCENT');
                case 'fixed':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 1);
                case 'fixed2':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 2);
                case 'standard':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 0, 'STANDARD');
                case 'standard1':
                    return ffn.nFormatter(ffn.nOn(element[column.field]), 1, 'STANDARD1');
                default:
                    return element[column.field];
            }
        }
        if (column && ['var', 'growth'].includes(column.type) && !element.itemType) {
            const value: number = parseFloat(element[column.field]);
            switch (column.format) {
                case 'percent':
                    return (
                        (value > 0 ? `+` : ``) +
                        ffn.nFormatter(
                            ffn.nOn(value),
                            1,
                            column.type != 'growth' ? 'PERCENTPOINTS' : 'PERCENTLESS'
                        )
                    );
                case 'fixed':
                    return (value > 0 ? `+` : ``) + ffn.nFormatter(ffn.nOn(value), 1);
                case 'fixed2':
                    return (value > 0 ? `+` : ``) + ffn.nFormatter(ffn.nOn(value), 2);
                case 'standard':
                    return (
                        (value > 0 ? `+` : ``) +
                        ffn.nFormatter(ffn.nOn(value), 0, 'STANDARD')
                    );
                case 'standard1':
                    return (
                        (value > 0 ? `+` : ``) +
                        ffn.nFormatter(ffn.nOn(value), 1, 'STANDARD1')
                    );
                default:
                    return element[column.field];
            }
        }
    }
    public static isSticky(column: string, target: string): boolean {
        return column === target ? true : false;
    }
}
