/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MomentModule } from 'ngx-moment';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DevExtremeModule, DxAccordionModule } from 'devextreme-angular';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ComponentModule } from './app/core/components/components.module';
import { AppComponent, CustomInterceptor } from './app/app.component';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
    withXsrfConfiguration
} from '@angular/common/http';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule, Title, bootstrapApplication } from '@angular/platform-browser';

if (environment.production) {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
    enableProdMode();
    provideHttpClient;
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            ComponentModule.forRoot(),
            CommonModule,
            BrowserModule,
            AppRoutingModule,
            DxAccordionModule,
            NgIdleKeepaliveModule.forRoot(),
            MomentModule,
            ModalModule.forRoot(),
            NgbModule,
            DevExtremeModule,
            ProgressbarModule.forRoot(),
            MatExpansionModule,
            MatSelectModule,
            MatFormFieldModule,
            MatGridListModule,
            MatToolbarModule,
            MatProgressSpinnerModule
        ),
        Title,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideHttpClient(
            withXsrfConfiguration({
                cookieName: 'pulse.x-csrf-token',
                headerName: 'x-csrf-token'
            })
        )
        //provideExperimentalZonelessChangeDetection() // --> TODO the day we want to remove polyfills ...
    ]
}).catch((err) => console.log(err));
