import { Injectable, Input } from '@angular/core';
import { ServerService } from './server.service';
//import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { EntityService } from './entity.service';
import { UserService } from './user.service';

//import * as XLSX from 'xlsx';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

@Injectable()
export class ExcelService {
    @Input() displayedColumns: any = [];
    @Input() columns: any = [];
    @Input() report: string;

    @Input() selectedCurrency: string;
    @Input() targetTable: any;

    constructor(
        public serverService: ServerService,
        public entityService: EntityService,
        private userservice: UserService
    ) {}
    /* 
      generateExcel2(): void {
          const offset = '6';

          const options = {
              filename: './streamed-workbook.xlsx',
              useStyles: true,
              useSharedStrings: true
          };
          const workbook = new Excel.Workbook(options);

          const worksheet = workbook.addWorksheet('Report', {
              properties: {
                   fill: {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'ffffff' }
                  }
    
              }
          });

          for (let rowIdx = 1; rowIdx < tableStart; rowIdx++) {
              worksheet.getRow(rowIdx).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'ffffff' }
              };

              for (let colIdx = 1; colIdx <= this.displayedColumns.length; colIdx++) {
                  worksheet.getCell(this.convertToNumberingScheme(colIdx) + rowIdx).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'ffffff' }
                  };
              }
          } 

       var worksheet = workbook.addWorksheet('Report', {properties:{tabColor:{argb:'FFC0000'}}});

          const columnsHeaders: any = [];
          let columnTitle = '';
          const columnsKey = [];
          const columnsLabel = [];
          let columnWidth = 10;
          let columnPadCount = 0;

          this.targetTable.nativeElement
              .querySelectorAll('tr.header-main')
              .forEach((thead) => {
                  const thead_th = [...thead.getElementsByTagName('th')];
                  let columnKeyId = 0;
                  thead_th.forEach((th, thidx) => {
                      if (!th.getAttribute('class').includes('mat-column-CLMNPAD')) {
                          columnWidth = 14;
                          columnTitle = th.textContent.replace(
                              /expand_moreexpand_less/g,
                              ''
                          );
                          if (th.getAttribute('class').includes('data')) {
                              columnsKey.push(
                                  this.convertToNumberingScheme(columnKeyId + 1) +
                                      (parseInt(offset) + 1)
                              );
                          }

                          columnsLabel.push(columnTitle);
                          columnsHeaders.push({
                              key: this.displayedColumns[thidx],
                              width: columnWidth
                          });
                          columnKeyId = columnKeyId + 1;
                      } else {
                          columnPadCount = columnPadCount + 1;
                      }
                  });
              });

       worksheet.mergeCells('A1', 'A3');
          worksheet.getCell('A1').value = this.report;
          worksheet.getCell('A1').font = { bold: true };

        //GENERATE HEADER GROUP First Line if Any

        //eslint-disable-next-line @typescript-eslint/no-this-alias
          const componentScope = this;
          const mHeadersCount =
              this.targetTable.nativeElement.querySelectorAll('tr.header-group').length;

          const tableStart = 3 + mHeadersCount;

          this.targetTable.nativeElement
              .querySelectorAll('tr.header-group')
              .forEach((thead, theadidx) => {
                  const thead_th = [...thead.getElementsByTagName('th')];

                  let cellRefStart = 1;
                  let cellRefEnd = 0;
                  const rowOffset = 3 + theadidx;
                  thead_th.forEach((th) => {
                      if (
                          th.getAttribute('colspan') &&
                          parseInt(th.getAttribute('colspan')) > 1
                      ) {
                          cellRefEnd =
                              cellRefStart + parseInt(th.getAttribute('colspan')) - 1; //- columnPadCount;
                          worksheet.mergeCells(
                              componentScope.convertToNumberingScheme(cellRefStart) +
                                  rowOffset,
                              componentScope.convertToNumberingScheme(cellRefEnd) +
                                  rowOffset
                          );
                          worksheet.getCell(
                              componentScope.convertToNumberingScheme(cellRefStart) +
                                  rowOffset
                          ).value = th.textContent;

                          worksheet.getCell(
                              componentScope.convertToNumberingScheme(cellRefStart) +
                                  rowOffset
                          ).font = {
                              size: 14,
                              bold: true,
                              color: { argb: '7e93ac' }
                          };

                          worksheet.getCell(
                              componentScope.convertToNumberingScheme(cellRefStart) +
                                  rowOffset
                          ).border = {
                              bottom: {
                                  style: 'thin',
                                  color: { argb: '7e93ac' }
                              }
                          };
                          worksheet.getCell(
                              componentScope.convertToNumberingScheme(cellRefStart) +
                                  rowOffset
                          ).alignment = { vertical: 'middle', horizontal: 'center' };
                      } else {
                          cellRefEnd = cellRefStart;
                      }
                      cellRefStart = cellRefEnd + 1;
                  });
              });

        //FORMAT TABLE HEADER ROW

          worksheet.getRow(tableStart).values = columnsLabel;
            worksheet.getRow(tableStart).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'efefef' }
          }; 
          worksheet.getRow(tableStart).font = {
              size: 14,
              bold: true,
              color: { argb: '7e93ac' }
          };
          worksheet.getRow(tableStart).alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true
          };

          worksheet.getRow(tableStart).border = {
              bottom: {
                  style: 'thin',
                  color: { argb: '7e93ac' }
              }
          };
          worksheet.columns = columnsHeaders;

          const spSpanSchema: any[] = [];

       //LOOP THROUGH TBODY
          this.targetTable.nativeElement.querySelectorAll('tr').forEach((eltr) => {
              const tdArray = [];
              const tdRowSpan = [];
              const tdColSpan = [];
              const classArray = [];

              const eltr_td = [...eltr.getElementsByTagName('td')];
              eltr_td.forEach((eltd) => {
                  if (!eltd.getAttribute('class').includes('mat-column-CLMNPAD')) {
                      tdRowSpan.push(eltd.getAttribute('rowspan'));
                      tdColSpan.push(eltd.getAttribute('colspan'));
                      let str = '';
                      const eltdInput = [...eltd.getElementsByTagName('input')];
                      if (eltdInput.length) {
                          str = eltdInput[0].val().toString();
                      } else {
                          str = eltd.textContent;
                      }
                      str = str.replace(/%/g, '');
                      str = str.replace(/ +/g, '');
                      str = str.replace(/expand_more/g, '');
                      str = str.replace(/chevron_right/g, '');
                      str = str.replace(/expand_less/g, '');

                      /* str = str.replace(/,/g, '');
                      str = str.replace(/Infinity%/g, '');
                      str = str.replace(/ - /g, '');
                      str = str.replace(/%/g, '');
                      str = str.replace(/insert_chart /g, '');
                      str = str.replace(/pts/g, ''); 

                       if (
                          eltd.getAttribute('class').includes('TITLE') != true &&
                          eltd.getAttribute('class').includes('LABEL') != true &&
                          eltd.getAttribute('class').includes('PROPID') != true &&
                          eltd.getAttribute('class').includes('SEG') != true &&
                          eltd.getAttribute('class').includes('name') != true &&
                          eltd.getAttribute('class').includes('COMMENTID') != true &&
                          str.replace(/ /g, '') != ''
                      ) {
                          if (eltd.getAttribute('class').includes('PERCENT') == true) {
                              str = str.replace(/ /g, '');
                              tdArray.push(parseFloat(str) / 100);
                              console.log('parseFloat(str) Percent', parseFloat(str));
                          } else {
                              str = str.replace(/ /g, '');
                              tdArray.push(parseFloat(str));
                              console.log('parseFloat(str)', parseFloat(str));
                          }
                      } else {
                          if (eltd.getAttribute('class').includes('COMMENTID') == true) {
                          }

                          if (
                              eltd.getAttribute('class').includes('COMMENTID') == true &&
                              eltd.getAttribute('rowspan') != 1
                          ) {
                              const commentValue = '';

                              if (commentValue != commentText) {
                                  tdArray.push(commentValue);
                                  commentText = commentValue;
                              }
                          } else {
                              if (
                                  eltd.getAttribute('class').includes('COMMENTID') != true
                              ) {
                                  tdArray.push(str);
                              }
                          }
                      } 

                      tdArray.push(str);
                      const classVal =
                          eltd.getAttribute('class') + eltr.getAttribute('class');
                      classArray.push(classVal);
                  }
              });

            //PUSH ROW DATA TO WORKSHEET
           console.log('tdRowSpan', eltr, tdRowSpan)

              if (tdArray.length > 0) {
                  const row = worksheet.addRow(tdArray);

                  row.eachCell(function (cell, i) {
                       row.fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: {
                              argb: componentScope.getCellBackGround(classArray[i - 1])
                          }
                      }; 
                      row.height = 15;
                      cell.numFmt = componentScope.getCellFormat(classArray[i - 1]);
                      cell.font = componentScope.getCellFont(classArray[i - 1]);
                      cell.border = componentScope.getCellBorder(classArray[i - 1]);
                      cell.alignment = componentScope.getCellAlignment(classArray[i - 1]);

                      if (
                          tdRowSpan[i - 1] != 1 &&
                          tdRowSpan[i - 1] != null &&
                          !isNaN(tdRowSpan[i - 1])
                      ) {
                       console.log('tdRowSpan[i - 1]', tdRowSpan[i - 1]);
                          const spanRef = cell._row._number + (tdRowSpan[i - 1] - 1);
                          spSpanSchema.push({
                              cellSpanRefStart: cell.address,
                              cellSpanRefEnd:
                                  componentScope.convertToNumberingScheme(
                                      cell._column._number
                                  ) + spanRef
                          });
                          cell.font = {};
                          cell.fill = {
                              type: 'pattern',
                              pattern: 'solid',
                              fgColor: { argb: 'f3efeb' }
                          };
                          cell.alignment = {
                              vertical: 'top',
                              horizontal: 'center',
                              wrapText: true
                          };
                       cell.fill = {bgColor:{argb:'#FF0000'}}
                      }
                  });
              }
          });

          worksheet.eachRow(function (row, i) {
              if (
                  row._cells[0] &&
                  row._cells[0].style &&
                  row._cells[0].style.font &&
                  row._cells[0].style.font.color &&
                  row._cells[0].style.font.color.argb == 'd8b464'
              ) {
                  worksheet.getRow(i).height = 25;
              }
          });

          spSpanSchema.forEach((cell) => {
              worksheet.mergeCells(cell.cellSpanRefStart, cell.cellSpanRefEnd);
              worksheet.getCell(cell.cellSpanRefStart).font = {
                  size: 14,
                  bold: true,
                  color: { argb: 'd8b464' }
              };
          });
       

          const fileName = this.report;

          workbook.xlsx.writeBuffer().then(function (buffer) {
            //done buffering
              const data: Blob = new Blob([buffer], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              });
              FileSaver.saveAs(data, fileName);
          });
      }
    */
    convertToNumberingScheme(number): string {
        const baseChar = 'A'.charCodeAt(0);
        let letters = '';
        do {
            number -= 1;
            letters = String.fromCharCode(baseChar + (number % 26)) + letters;
            number = (number / 26) >> 0; // quick `floor`
        } while (number > 0);

        return letters;
    }

    getCellAlignment(classStr): any {
        return {
            horizontal:
                classStr.includes('mat-column-LABEL') &&
                this.displayedColumns[0] == 'LABEL'
                    ? 'left'
                    : 'center',
            wrapText: true
        };
    }
    getCellBorder(classStr): any {
        const borderStyles = {
            bottom: { style: 'thin', color: { argb: 'd8b464' } }
        };
        if (classStr.includes('SUBTITLE')) {
            return borderStyles;
        } else if (classStr.includes('brand_total')) {
            return borderStyles;
        } else if (classStr.includes('rowitem') && classStr.includes('var')) {
            return {
                left: { style: 'thin', color: { argb: 'ecd2a4' } }
            };
        } else if (classStr.includes('subitem') && classStr.includes('var')) {
            return {
                left: { style: 'thin', color: { argb: 'a59476' } }
            };
        } else if (classStr.includes('data')) {
            return {
                left: { style: 'thick', color: { argb: 'ffffff' } }
            };
        } else {
            return null;
        }
    }

    getCellBackGround(classStr): string {
        const color = 'ffffff';

        if (classStr.includes('subitem')) {
            return 'e5d5b1';
        } else {
            return color;
        }
    }

    getCellFormat(classStr): string {
        let nbrFormat = '#';
        const digitChr = '.';
        const thousandChr = ',';
        if (classStr) {
            if (classStr.includes('PERCENT') && classStr.includes('var_val')) {
                nbrFormat = '#' + thousandChr + '##0' + digitChr + '0pt';
            }
            if (classStr.includes('PERCENT') && classStr.includes('var_val') == false) {
                nbrFormat = '#' + thousandChr + '##0' + digitChr + '0%';
            }

            if (classStr.includes('FIXED')) {
                nbrFormat = '#' + thousandChr + '##0' + digitChr + '0';
            }
            if (classStr.includes('STANDARD')) {
                nbrFormat = '#' + thousandChr + '##0';
            }
            if (classStr.includes('REVPERCENT')) {
                nbrFormat = '#' + thousandChr + '##0' + digitChr + '0%';
            }
        }

        return nbrFormat;
    }

    getCellFont(classStr): any {
        let font = {};
        if (classStr) {
            if (classStr.includes('BOLD') || classStr.includes('brand_total')) {
                if (classStr.includes('text-red')) {
                    font = { bold: true, color: { argb: 'ff0000' } };
                } else if (classStr.includes('text-green')) {
                    font = { bold: true, color: { argb: '009900' } };
                } else {
                    font = { bold: true };
                }
            } else if (classStr.includes('TITLE')) {
                font = { bold: true, color: { argb: 'd8b464' } };
            } else {
                if (classStr.includes('text-red')) {
                    font = { bold: false, color: { argb: 'ff0000' } };
                } else if (classStr.includes('text-green')) {
                    font = { bold: false, color: { argb: '009900' } };
                } else {
                    font = { bold: false };
                }
            }
        }

        return font;
    }

    downloadCSV(data, filename = 'data', fields): void {
        const csvData = this.ConvertToCSV(data, fields);

        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser =
            navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {
            //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    ConvertToCSV(objArray, headerList): string {
        const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = 'S.No,';

        for (const index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = i + 1 + '';
            for (const index in headerList) {
                const head = headerList[index];

                line += ',' + array[i][head];
            }
            str += line + '\r\n';
        }
        return str;
    }

    exportJsonToExcel(jsonData: any[], excelFileName: string): void {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        // Add header row
        const headerRow = worksheet.addRow(Object.keys(jsonData[0]));

        // Set header row style
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'EFEFEF' } // light grey
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Add data rows
        jsonData.forEach((data) => {
            worksheet.addRow(Object.values(data));
        });

        // Auto fit columns
        worksheet.columns.forEach((column) => {
            column.width = 25;
        });

        // Generate Excel file and save
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            FileSaver.saveAs(blob, excelFileName + '.xlsx');
        });
    }

    generateExcel(headerGroup: boolean = false): void {
        const sheetName = 'Report';
        const fileName = this.report;
        const targetTableElm = this.targetTable.nativeElement;
        const thData = [];
        let tdData = [];
        const trData = [];
        let columnTitle = '';
        const groups = [];
        // extract the thead from targetTableElm.innerHTML and push to thData
        const thead = targetTableElm
            .querySelector('thead')
            .innerHTML.toString()
            .replace(/expand_moreexpand_less/g, '');
        const headerLines = (thead.match(/<\/tr>/g) || []).length;
        trData.push(thead);

        targetTableElm.querySelectorAll('tr').forEach((thead) => {
            const thead_td = [...thead.getElementsByTagName('td')];
            tdData = [];
            thead_td.forEach((td, index) => {
                columnTitle = td.textContent;
                if (index > 0) {
                    columnTitle = columnTitle.replace(/%/g, '');
                }
                columnTitle = columnTitle.replace(/ +/g, '');
                columnTitle = columnTitle.replace(/expand_more/g, '');
                columnTitle = columnTitle.replace(/chevron_right/g, '');
                columnTitle = columnTitle.replace(/expand_less/g, '');
                columnTitle = columnTitle.replace(/expand_moreexpand_less/g, '');
                if (columnTitle.slice(-3) == ')pp' || columnTitle.match('[0-9]+pp')) {
                    columnTitle = columnTitle.replace(/pp/g, '');
                    columnTitle = columnTitle.replace(')pp', '');
                    columnTitle = columnTitle.replace('(', '');
                    columnTitle = parseFloat(columnTitle).toString();
                }
                if (columnTitle.indexOf('k%') > 0 || columnTitle.match('[0-9]+k')) {
                    columnTitle = columnTitle.replace(/k/g, '');
                    const realNumber = parseFloat(columnTitle) * 1000;
                    columnTitle =
                        realNumber > 0 ? realNumber.toString() : realNumber.toString();
                }
                tdData.push('<td>' + columnTitle + '</td>');
            });
            if (tdData.length > 0) {
                trData.push('<tr>' + tdData.join('') + '</tr>');
            }
        });
        const htmlObject = document.createElement('table');
        htmlObject.innerHTML = trData.toString();
        const wb = XLSX.utils.table_to_book(htmlObject, <XLSX.Table2SheetOpts>{
            sheet: sheetName,
            cellStyles: true
        });
        // Add styles to the sheet
        // loop over wb.Sheets.Report attributes that starts with "A"
        for (const index in wb.Sheets.Report) {
            if (index.startsWith('A')) {
                wb.Sheets.Report[index].s = {
                    font: {
                        name: 'arial',
                        sz: 12,
                        bold: true
                    }
                };
            }
            // extract the number from index
            const num = parseInt(index.slice(1));
            if (num > 0 && num <= headerLines) {
                if (num === headerLines) {
                    // draw bottom border
                    wb.Sheets.Report[index].s = {
                        font: {
                            name: 'arial',
                            sz: 11,
                            bold: true
                        },
                        border: {
                            bottom: { style: 'thin', color: { theme: 4 } }
                        }
                    };
                } else if (num === 1) {
                    // draw side border
                    wb.Sheets.Report[index].s = {
                        font: {
                            name: 'arial',
                            sz: 11,
                            bold: true
                        },
                        border: {
                            left: { style: 'thin', color: { theme: 4 } },
                            right: { style: 'thin', color: { theme: 4 } }
                        }
                    };
                } else {
                    wb.Sheets.Report[index].s = {
                        font: {
                            name: 'arial',
                            sz: 12,
                            bold: true
                        }
                    };
                }
            }
        }

        /*
        wb.Sheets.Report["C2"].s =  {
            font: {
                name: 'arial',
                sz: 24,
                bold: true,
                color: "#F2F2F2"
            },
            fill: {
                fgColor: {
                    rgb: "FF0000"
                }
            }
        };
        */
        // extra cleaning
        for (const index in wb.Sheets.Report) {
            if (index.startsWith('A')) {
                if (wb.Sheets.Report[index].v === 'expand_moreexpand_less') {
                    wb.Sheets.Report[index].v = '';
                }
            }
        }

        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }
}
