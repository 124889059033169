import 'datatables.net-responsive-dt';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-fixedcolumns';
import 'datatables.net-fixedheader';
import 'datatables.net-scroller';
import {
    Component,
    Inject,
    Injectable,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    NavigationEnd,
    NavigationStart,
    Router,
    RouterOutlet,
    RoutesRecognized
} from '@angular/router';
import { GlobalService } from './core/services/global.service';
import { ScriptLoaderService } from './core/services/script-loader.service';
import { Helpers } from './helpers';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import { UserService } from './core/services/user.service';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { Observable, Subscription, catchError, filter, throwError } from 'rxjs';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { ThemeService } from 'app/core/services/theme.service';

declare const gtag: Function; // eslint-disable-line
declare const $;
import { ServerService } from './core/services/server.service';
import config from 'devextreme/core/config';
import { CsrfService } from './core/services/csrf.service';
import { environment } from 'environments/environment';
import { LoadingComponent } from './main/loading/loading.component';
import { AlertComponent } from './main/alert/alert.component';
import { ScrollTopComponent } from './main/scroll-top/scroll-top.component';
import { EntitySidebarComponent } from './main/entity-sidebar/entity-sidebar.component';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
config({
    editorStylingMode: 'underlined',
    licenseKey: environment.licenseKey
});
@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./app.component.scss'],
    imports: [
        RouterOutlet,
        EntitySidebarComponent,
        ScrollTopComponent,
        AlertComponent,
        LoadingComponent,
        ModalModule,
        NgClass,
        NgIf
    ]
})
export class AppComponent implements OnInit {
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    globalBodyClassDark =
        'm-page--loading-non-block theme--dark m-page--wide m-header--fixed m-header--fixed-mobile m-footer--push m-aside--offcanvas-default';
    globalBodyClassDefault =
        'm-page--loading-non-block theme--default m-page--wide m-header--fixed m-header--fixed-mobile m-footer--push m-aside--offcanvas-default';
    private subscriptions: Subscription[] = [];
    private title: string;
    // alertHidden to prevent seeing big red boxes on initial page render
    public alertHidden = true;
    public modalRef: BsModalRef;
    public alertData: {
        id: string;
        title: string;
        description: string;
        author: string;
        isWebinar: number;
    };
    @ViewChild('alertModal') alertModal: ModalDirective;
    public news;
    public transformedNews;
    constructor(
        private router: Router,
        public globalService: GlobalService,
        private titleService: Title,
        private script: ScriptLoaderService,
        private userService: UserService,
        private serverService: ServerService,
        private idle: Idle,
        private keepalive: Keepalive,
        private http: HttpClient,
        private csrfService: CsrfService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private themeService: ThemeService
    ) {
        // sets the ping interval to 15 seconds
        keepalive.interval(15);
        keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
        this.subscriptions.push(
            this.userService.on_ready.subscribe((userLoggedIn) => {
                if (userLoggedIn) {
                    const _timeBeforeOauthExp: number =
                        Math.abs(
                            this.userService.user.session_exp - new Date().getTime()
                        ) / 1000;
                    idle.setTimeout(_timeBeforeOauthExp);
                    idle.watch();
                } else {
                    idle.stop();
                }
            })
        );
        /** START : Code to Track Page View using gtag.js */
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag('event', 'page_view', {
                    page_path: event.urlAfterRedirects
                });
            });
        /** END : Code to Track Page View  using gtag.js */

        //Add dynamic title for selected pages - Start
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const title = this.getTitle(
                    router.routerState,
                    router.routerState.root
                ).join(' > ');
                titleService.setTitle(title);
            }
        });
        //Add dynamic title for selected pages - End
        console.debug('end constructor');
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(...this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }
    reset(): void {
        this.idle.watch();
        this.timedOut = false;
    }

    hideAlertModal(): void {
        this.alertModal.hide();
    }
    onAlertAcknowledge(): void {
        localStorage.setItem(this.alertData?.id, '1');
        this.alertModal.hide();
    }

    async ngOnInit(): Promise<void> {
        console.debug('start ngOnInit');
        const url = window.location.href;
        if (url.includes('?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            this.userService.oAuthCode = httpParams.get('code');
        }

        // validate csrf token here
        await this.verifyCsrf();
        // green light for AuthPage
        console.info('csrf token verified -> csrfService.on_ready.next(true)');
        this.csrfService.on_ready.next(true);

        this.script
            .loadScripts('body', ['assets/demo/demo5/base/scripts.bundle.js'], true)
            .then(() => {
                this.script
                    .loadScripts(
                        'head',
                        ['./assets/vendors/custom/freshdesk_v2.js'],
                        true
                    )
                    .then(() => {
                        this.router.events.subscribe((route) => {
                            if (route instanceof NavigationStart) {
                                const theme = localStorage.getItem('theme');
                                Helpers.setLoading(true);
                                if (theme && theme === 'dark') {
                                    Helpers.bodyClass(this.globalBodyClassDark);
                                } else {
                                    Helpers.bodyClass(this.globalBodyClassDefault);
                                }
                            }
                            if (route instanceof RoutesRecognized) {
                                this.title = null;
                                let _route = route.state.root;
                                while (_route.firstChild) {
                                    _route = _route.firstChild;
                                    if (_route.data && _route.data.title) {
                                        this.title = _route.data.title;
                                    }
                                }
                            }
                            if (route instanceof NavigationEnd) {
                                // subscribe to router events and send page views to Google Analytics
                                if (this.userService.user)
                                    this.serverService.post(
                                        'access/logAccess',
                                        {
                                            page: route.urlAfterRedirects,
                                            user_id: this.userService.user.user_id
                                        },
                                        (data) => {
                                            if (
                                                route?.url !== '/Logout' &&
                                                route?.url !== '/logout'
                                            ) {
                                                if (data?.length) {
                                                    this.alertHidden = false;
                                                    this.alertData = data[0];
                                                    const alertSeen =
                                                        +localStorage.getItem(
                                                            this.alertData?.id
                                                        );
                                                    if (!alertSeen) {
                                                        this.alertModal.show();
                                                    }
                                                } else {
                                                    this.alertData = undefined;
                                                }
                                            }
                                        }
                                    );

                                this.globalService.loading(false);
                                Helpers.setLoading(false);
                                if (this.title) {
                                    // Update title
                                    this.titleService.setTitle(this.title);
                                }
                            }
                        });
                    });
            });
        // window.FreshWidget.init("", {"loadOnEvent": 'immediate',"queryString": "&widgetType=popup", "utf8": "✓", "widgetType": "popup", "buttonType": "text", "buttonText": "Support", "buttonColor": "white", "buttonBg": "#BF9000", "alignment": "2", "offset": "235px", "formHeight": "500px", "url": "https://radissonhotelgroup.freshservice.com"} );
    }

    public async sendWebinarInvite(event, webinar): Promise<void> {
        this.serverService.post(
            'dashboard/docs/add_email',
            {
                email: event.email,
                webinar: webinar
            },
            (data) => {}
        );
        localStorage.setItem(this.alertData?.id, '1');
        this.hideAlertModal();
    }

    // ngAfterViewInit(): void {
    //     this.router.events.subscribe((event) => {
    //         // I check for isPlatformBrowser here because I'm using Angular Universal, you may not need it
    //         if (event instanceof NavigationEnd) {
    //             console.log(ga); // Just to make sure it's actually the ga function
    //             ga('set', 'page', event.urlAfterRedirects);
    //             ga('send', 'pageview');
    //         }
    //     });
    // }
    private async verifyCsrf(): Promise<void> {
        const _isCsrfTokenValid = await this.csrfService.validateCsrfToken();
        if (!_isCsrfTokenValid) {
            const _newCsrfToken = await this.csrfService.refreshCsrfTokenPromise();
            this.csrfService.set(_newCsrfToken);
        }
    }

    public ngAfterViewInit(): void {
        this.setUserSelectedTheme();
        this.subscriptions.push(
            this.themeService.themeChanged$.subscribe((theme: string) => {
                this.switchTheme(theme);
            })
        );
    }

    public setUserSelectedTheme(): void {
        const theme = localStorage.getItem('theme');
        const htmlNode = this.document.getElementsByTagName('html')[0];
        const bodyNode = this.document.getElementsByTagName('body')[0];
        if (!theme) {
            this.renderer.setAttribute(htmlNode, 'theme-data', 'light');
            this.renderer.removeClass(bodyNode, 'theme--dark');
            this.renderer.addClass(bodyNode, 'theme--default');
            this.themeService.mode = 'light';
            return;
        }
        if (theme === 'dark') {
            this.renderer.setAttribute(htmlNode, 'theme-data', 'dark');
            this.renderer.removeClass(bodyNode, 'theme--default');
            this.renderer.addClass(bodyNode, 'theme--dark');
            this.themeService.mode = 'dark';
        } else {
            this.renderer.setAttribute(htmlNode, 'theme-data', 'light');
            this.renderer.removeClass(bodyNode, 'theme--dark');
            this.renderer.addClass(bodyNode, 'theme--default');
            this.themeService.mode = 'light';
        }
    }

    public switchTheme(theme: string): void {
        if (theme === this.themeService.mode) return;
        const htmlNode = this.document.getElementsByTagName('html')[0];
        const bodyNode = this.document.getElementsByTagName('body')[0];
        const attr = htmlNode.getAttribute('theme-data');
        if (attr === 'dark') {
            this.renderer.setAttribute(htmlNode, 'theme-data', 'light');
            this.renderer.removeClass(bodyNode, 'theme--dark');
            this.renderer.addClass(bodyNode, 'theme--default');
            this.themeService.mode = 'light';
        } else {
            this.renderer.setAttribute(htmlNode, 'theme-data', 'dark');
            this.renderer.removeClass(bodyNode, 'theme--default');
            this.renderer.addClass(bodyNode, 'theme--dark');
            this.themeService.mode = 'dark';
        }
        localStorage.setItem('theme', this.themeService.mode);
        // window.location.reload();
    }
}
@Injectable()
export class CustomInterceptor implements HttpInterceptor {
    constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
        //console.log('CustomInterceptor constructor');
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log('CustomInterceptor intercept: ' + req.url);
        const cookieheaderName = 'x-csrf-token';
        const csrfToken = this.tokenExtractor.getToken() as string;
        if (csrfToken !== null && !req.headers.has(cookieheaderName)) {
            //console.log('clone req: ' + req.url + ' with csrfToken: ' + csrfToken);
            req = req.clone({
                headers: req.headers.set(cookieheaderName, csrfToken)
            });
        }
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    console.log('CustomInterceptor 401 error');
                } else {
                    console.log('CustomInterceptor error: ' + error.message);
                }
                return throwError(error);
            })
        );
    }
}
