import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { LogoutPage } from './pages/logout/logout.page';
import { rhgRoutes } from './clients/rhg/config/rhgRoutes';
const DASHBOARD_ROUTES = [...rhgRoutes];
const routes: Routes = [
    {
        path: 'login',
        loadComponent: () => import('./pages/auth/auth.page').then((m) => m.AuthPage)
    },
    { path: 'logout', component: LogoutPage },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: '',
        component: DashboardPage,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        children: [
            {
                path: 'admin',
                loadComponent: () => import('./pages/admin/admin').then((m) => m.Admin)
            },
            {
                path: '404',
                loadComponent: () =>
                    import('./pages/not-found/not-found.page').then((m) => m.NotFoundPage)
            },
            ...DASHBOARD_ROUTES,
            {
                path: '**',
                redirectTo: 'dashboard/index',
                pathMatch: 'full'
            }
        ]
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // Tell the router to use the HashLocationStrategy.
            useHash: true,
            enableTracing: false,
            initialNavigation: 'enabledNonBlocking',
            paramsInheritanceStrategy: 'always'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
