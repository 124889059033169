import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboardService } from 'app/core/services/dashboard.service';
import { ServerService } from 'app/core/services/server.service';
import { abortUnsubscribe } from 'app/shared/utils/shared-methods.utils';
import { Subject, Subscription } from 'rxjs';
import { SectionCommentsDialogComponent } from '../popup/section-comments-dialog/section-comments-dialog.component';
import { PopupService } from '../popup/popup.service';

@Component({
    selector: 'section-comments',
    templateUrl: './section-comments.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class SectionCommentsComponent implements OnInit, OnDestroy {
    @Input() public sectionId: string = '';
    public info: any; // { badge: number, error?: any }
    private subscription: Subscription;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private timer: any;
    constructor(
        private dashboardService: DashboardService,
        private serverService: ServerService,
        private popupService: PopupService
    ) {}
    public ngOnInit(): void {
        // DZ COMMENTS
        // this.subscription = this.dashboardService.on_init.subscribe((data) => {
        //     if (data && !this.timer) {
        //         this._getInfo();
        //         /*
        //         this.timer = setInterval(() => {
        //             this._getInfo();
        //         }, 60000); // 1 min
        //         */
        //     }
        // });
    }
    public openDialog(): void {
        if (this.info.error) {
            // Do something?
        } else {
            this.popupService.open(
                SectionCommentsDialogComponent as Component,
                (componentInstance) => {
                    componentInstance.section_id = this.sectionId;
                },
                undefined,
                () => {
                    this._getInfo();
                }
            );
        }
    }
    private _getInfo(): void {
        abortUnsubscribe(this.ngUnsubscribe);
        this.serverService.post(
            'reader-comments-tool/infoSection',
            {
                dashboard_id: this.dashboardService.id,
                section_id: this.sectionId
            },
            (data: any) => {
                this.info = data;
            },
            (error) => {
                console.error(error);
                this.info = { badge: 0, error: error };
            },
            this.ngUnsubscribe,
            true
        );
    }
    public ngOnDestroy(): void {
        abortUnsubscribe(this.ngUnsubscribe, true);
        if (this.timer) {
            clearInterval(this.timer);
        }
        // DZ COMMENTS
        // this.subscription.unsubscribe();
    }
}
