import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export class LazyLoadExcelService {
    constructor() {}

    // Method to export JSON to Excel
    exportToExcel(data: any[], displayedColumns: any[], title: string): void {
        // Convert JSON to worksheet
        const repairedData = this.parseJsonData(data, displayedColumns);
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(repairedData);

        // Create a workbook
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data']
        };

        // Generate Excel file buffer
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        // Save the Excel file
        this.saveAsExcelFile(excelBuffer, title);
    }

    // Method to save Excel file
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs(data, fileName + EXCEL_EXTENSION);
    }
    // Function to repair JSON data
    public parseJsonData(data: any[], displayedColumns: any[]): any[] {
        const result = [];
        const exportColumns = displayedColumns;
        const parents = data.filter((item) => item.parentId == '');
        for (let index = 0; index < parents.length; index++) {
            const parent_Id = parents[index].id;
            parents[index] = Object.keys(parents[index])
                .filter((objKey) => exportColumns.includes(objKey))
                .reduce((newObj, key) => {
                    newObj[key] = parents[index][key];
                    return newObj;
                }, {});
            result.push(parents[index]);
            const children = data.filter((item) => item.parentId == parent_Id);
            if (children.length) {
                for (let index = 0; index < children.length; index++) {
                    const children_Id = children[index].id;
                    children[index] = Object.keys(children[index])
                        .filter((objKey) => exportColumns.includes(objKey))
                        .reduce((newObj, key) => {
                            newObj[key] =
                                key == 'name'
                                    ? `--${children[index][key]}`
                                    : children[index][key];
                            return newObj;
                        }, {});
                    result.push(children[index]);
                    const lv3Children = data.filter(
                        (item) => item.parentId == children_Id
                    );
                    if (lv3Children.length) {
                        for (let index = 0; index < lv3Children.length; index++) {
                            const lv3Children_Id = lv3Children[index].id;
                            lv3Children[index] = Object.keys(lv3Children[index])
                                .filter((objKey) => exportColumns.includes(objKey))
                                .reduce((newObj, key) => {
                                    newObj[key] =
                                        key == 'name'
                                            ? `----${lv3Children[index][key]}`
                                            : lv3Children[index][key];
                                    return newObj;
                                }, {});
                            result.push(lv3Children[index]);
                            const lv4Children = data.filter(
                                (item) => item.parentId == lv3Children_Id
                            );
                            if (lv4Children.length) {
                                for (let index = 0; index < lv4Children.length; index++) {
                                    lv4Children[index] = Object.keys(lv4Children[index])
                                        .filter((objKey) =>
                                            exportColumns.includes(objKey)
                                        )
                                        .reduce((newObj, key) => {
                                            newObj[key] =
                                                key == 'name'
                                                    ? `------${lv4Children[index][key]}`
                                                    : lv4Children[index][key];
                                            return newObj;
                                        }, {});
                                    result.push(lv4Children[index]);
                                }
                            }
                        }
                    }
                }
            }
        }
        return result;
    }
}
// Constants for file type and extension
const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
