import {
    AfterViewInit,
    Component,
    OnInit,
    QueryList,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { DashboardService } from 'app/core/services/dashboard.service';
import { EntityService } from 'app/core/services/entity.service';
import { GlobalService } from 'app/core/services/global.service';
import { Entity } from 'app/shared/interfaces/EntityInterface';
import { EventManagerService } from 'app/shared/utils/event-manager.service';
import { DxListComponent, DxTreeListComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { DashboardFilter } from './entity-sidebar.constants';
import { Observable } from 'rxjs';
import { checkAllowedHtl } from 'app/shared/utils/shared-methods.utils';
import { environment } from 'environments/environment';
import { DxiColumnModule, DxoSelectionModule } from 'devextreme-angular/ui/nested';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxListModule } from 'devextreme-angular/ui/list';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { UnwrapTagDirective } from '../directives/unwrap-tag.directive';
declare const $;

/**
 * This component will show the filters at the head of the page and
 * trigger whenever it's needed the dashboardService.updateFilters.
 *
 * To use it, on the selected route, add this component at the same path but setting the outlet to 'dashboard-filters'.
 * The main component on this route will need to call dashboardService.setFilters.
 * (there's an example on pace-curve.module.ts).
 *
 * This component will obtain the entity filters which are the binded ones to the entity-sidebar. This kind of filters can
 * be showed just by adding the id/ids at the filtersOrder param on initFilters method.
 *
 * Also, this kind of filters aren't going to be as a request param, but instead, the entities of entityService will be updated.
 */
@Component({
    selector: 'entity-sidebar',
    templateUrl: './entity-sidebar.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./entity-sidebar.component.scss'],
    imports: [
        UnwrapTagDirective,
        NgIf,
        NgFor,
        NgClass,
        DxListModule,
        DxTemplateModule,
        DxTreeListModule,
        DxoSelectionModule,
        DxiColumnModule,
        AsyncPipe
    ]
})
export class EntitySidebarComponent implements OnInit, AfterViewInit {
    @ViewChildren('dxComponents') dxComponents: QueryList<
        DxListComponent | DxTreeListComponent
    >;
    public expanded = false;
    /* public areasMenu = [
        { label: 'Ops Area', order: 1, value: 'opsarea' },
        { label: 'Rev Area', order: 2, value: 'revarea' },
        { label: 'Old Ops', order: 3, value: 'prevopsarea' }
    ]; */
    public filters: DashboardFilter[];
    private hotelFilter: DashboardFilter;
    private entityMapFilters: Map<string, DashboardFilter> = new Map<
        string,
        DashboardFilter
    >();
    private customRequiredMapFilters: Map<string, DashboardFilter> = new Map<
        string,
        DashboardFilter
    >();
    private entitiesPromise: Promise<any>;
    private filtersPromise: Promise<any>;
    private dxMapPromise: Promise<any>;
    public entitiesResolve: any;
    public filtersResolve: any;
    public dxMapResolve: any;
    public hotelListDataTable;
    public showHotelList = false;
    public lockHotel = false;
    public allowedHtlIds: number[];
    public hotelListHeigth = 210;
    public company = environment.company;
    /**
     * In case there it's no Hotels filter, we needed to save the selecteds for know the binding
     */
    private selectedsHotelsNotDx: Entity[];
    private updateEntities: boolean;
    private subscriptions: Subscription[] = [];
    private forceAllEntities: boolean;
    public shouldDisplayDefaultHotelList$: Observable<boolean>;
    /**
     * Resolve function to entityService.initSelectedsPromise
     * (see more info at entity-service.ts)
     */
    public initSelectedsResolve: any;
    constructor(
        private eventManagerService: EventManagerService,
        private dashboardService: DashboardService,
        private entityService: EntityService,
        private globalService: GlobalService
    ) {
        this.entityService.initSelectedsPromise = new Promise<any>((resolve, _reject) => {
            this.initSelectedsResolve = resolve;
        });
    }
    public ngOnInit(): void {
        // Init Promises
        this.entitiesPromise = new Promise<any>((resolve, _reject) => {
            this.entitiesResolve = resolve;
        });
        this.filtersPromise = new Promise<DashboardFilter[]>((resolve, _reject) => {
            this.filtersResolve = resolve;
        });
        this.dxMapPromise = new Promise<any>((resolve, _reject) => {
            this.dxMapResolve = resolve;
        });
        // Override dashboardService variables
        this.dashboardService.initFilters = this.initFilters.bind(this);
        this.dashboardService.onSourceChange = this.onSourceChange.bind(this);
        this.dashboardService.dxMapPromise = this.dxMapPromise;
        // Set subscriptions
        this._registerChanges();
        this.shouldDisplayDefaultHotelList$ =
            this.entityService.shouldDisplayDefaultHotelList();

        this.eventManagerService.subscribe('updateFilters', (_response) => {
            this._updateSelectedsEntityFilters();
        });
    }

    public ngAfterViewInit(): void {
        this.filtersPromise.then(() => {
            setTimeout(() => {
                this.dashboardService.dxListFiltersMap = new Map<
                    string,
                    DxListComponent
                >();
                this.dxComponents.forEach((item) => {
                    if (!(item instanceof DxTreeListComponent)) {
                        this.dashboardService.dxListFiltersMap.set(
                            item.elementAttr.id,
                            item
                        );
                    }
                });
                this.dxMapResolve();
                // to ensure users with no default selections see something
                this.entityMapFilters.get('hotel').selectedKeys = this.entityService
                    .getEntities()
                    .map((el) => el.id);
                const selectedEntities = localStorage.getItem('selected-entity');
                if (selectedEntities) {
                    this.entityMapFilters.get('hotel').selectedKeys =
                        selectedEntities.split(',');
                }
                this._updateSelectedsEntityFilters();
                this.updateEntityServiceSelecteds();

                this.entityService.getDefaultHotelList(-1, () => {});
            }, 0);
        });
        // pay attention to the order of the filters
        if (environment.company === 'rhg') {
            this.dashboardService.initFilters(undefined, [
                'hotel',
                'country',
                'city',
                'opsarea',
                'revarea',
                'prevopsarea',
                'md_area',
                'theatre',
                'contract',
                'brand',
                'perimeter_grp',
                'perimeter_5yp',
                'status',
                'me_cluster',
                'room_count',
                'hotel_type',
                'market_type',
                'focus',
                'rmc_flag',
                'cc'
            ]);
        } else {
            this.dashboardService.initFilters(undefined, [
                'hotel',
                'country',
                'hotel_place',
                'city',
                'opsarea',
                'dop',
                'rr_fsm',
                'drm_zone',
                'arm_zone',
                'theatre',
                'contract',
                'brand',
                'perimeter_grp',
                'perimeter_5yp',
                'hotel_type',
                'room_count',
                'stars',
                'pms'
            ]);
        }
    }
    /**
     * The main propouse of this component.
     * Update the dashboardService.filtersSelected and trigger the behaviors
     */
    public updateFilters(): void {
        const _warningMessages: string[] = [];
        if (this.updateEntities) {
            // Check for at least one entity selected
            const _selectedHotels = this.entityMapFilters.get('hotel').selectedKeys;
            if (_selectedHotels && _selectedHotels.length) {
                this.dashboardService.selectedEntities = [...new Set(_selectedHotels)];
                this.dashboardService.selectedHtlId = [].concat(
                    ...[...new Set(_selectedHotels)].map((el) =>
                        this.entityService.getHtlIdByCode(el)
                    )
                );
                this.eventManagerService.broadcast({
                    name: 'updatedEntitiesOutsideSidebar',
                    data: _selectedHotels
                });
            } else {
                _warningMessages.push('- Please make sure to select at least one Hotel.');
            }
        }
        if (_warningMessages.length) {
            this.globalService.alert('Warning', _warningMessages);
        } else {
            this.updateEntities = false;
            this.entityService.setSelectedEntities(
                this.entityMapFilters.get('hotel').selectedKeys
            );
            this.updateEntityServiceSelecteds();
            $('#m_quick_sidebar_close').click();
        }
    }
    /**
     * Saves the User's default hotel list
     */
    saveDefaultHotelList(selection): void {
        this.updateFilters();
        this.entityService.setDefaultHotelList(selection);
    }
    /**
     * Restores the User's default hotel list
     */
    useDefaultHotelList(selection): void {
        this.entityService.getDefaultHotelList(
            selection,
            (data: { htl_ids: string; label: string[] }) => {
                const _selectedHotels = data.htl_ids.split(',');
                this.eventManagerService.broadcast({
                    name: 'updatedEntitiesOutsideSidebar',
                    data: _selectedHotels
                });
                this.entityMapFilters.get('hotel').selectedKeys = _selectedHotels;
                this.updateFilters();
                this.updateEntityServiceSelecteds();
            }
        );
    }
    /**
     * Set filters on the view.
     * The customFilters can be undefined if we want to use only the entityFilters.
     * In any case, if we want to use the entityFilters, the key of the filters we want to use
     * must be on the array filtersOrder
     */
    public initFilters(
        customFilters: DashboardFilter[],
        filtersOrder?: string[]
    ): Promise<DashboardFilter[]> {
        this.entitiesPromise.then(() => {
            let _hasEntityFilters = false;
            const _customFiltersMap = new Map<string, DashboardFilter>();
            if (customFilters) {
                for (let i = 0; i < customFilters.length; i++) {
                    _customFiltersMap.set(customFilters[i].id, customFilters[i]);
                    // Also store the required ones at this.customRequiredMapFilters
                    if (customFilters[i].required) {
                        this.customRequiredMapFilters.set(
                            customFilters[i].id,
                            customFilters[i]
                        );
                    }
                }
            }
            if (filtersOrder) {
                const _filters: DashboardFilter[] = [];
                for (let i = 0; i < filtersOrder.length; i++) {
                    const _dashboardFilter = _customFiltersMap.get(filtersOrder[i]);
                    if (_dashboardFilter) {
                        _filters.push(_dashboardFilter);
                    } else {
                        const _entityFilter = this.entityMapFilters.get(filtersOrder[i]);
                        if (_entityFilter) {
                            _filters.push(_entityFilter);
                            _hasEntityFilters = true;
                            if (_entityFilter.id === 'hotel') {
                                this.hotelFilter = _entityFilter;
                            }
                        }
                    }
                }
                this.dashboardService.filtersMap = new Map<string, DashboardFilter>();
                for (let i = 0; i < _filters.length; i++) {
                    this.dashboardService.filtersMap.set(_filters[i].id, _filters[i]);
                }
                this.filters = _filters;
            } else {
                this.dashboardService.filtersMap = _customFiltersMap;
                this.filters = [...new Set(_customFiltersMap.values())];
            }
            this.dashboardService.hasEntityFilters = _hasEntityFilters;
            this._enableDxComponents();
            this.filtersResolve();
        });
        return this.filtersPromise;
    }
    /**
     * When a filter is selected/deselected, trigger this method
     * If the filterNode.onFilterChange has been added and it's not disabled, trigger it
     */
    public onFilterChange(ev: any, filter: DashboardFilter, _selectAll: boolean): void {
        filter.selectionChanged = true;
        if (filter.onFilterChange !== undefined && filter.enabled) {
            if (ev.value !== undefined && this.entityMapFilters.get(filter.id)) {
                if (filter.searchExpr === undefined) {
                    this.forceAllEntities = ev.value;
                } else {
                    const _dxList = this.dashboardService.dxListFiltersMap.get(filter.id);
                    if (!(_dxList.searchValue && _dxList.searchValue.length)) {
                        this.forceAllEntities = ev.value;
                    }
                }
            }
            this._disableDxComponents(filter);
            if (this.forceAllEntities !== undefined) {
                this._onForceAllEntities(filter);
            } else {
                filter.onFilterChange(ev, filter);
            }
            this._enableDxComponents();
            this.forceAllEntities = undefined;
        }
    }
    /**
     * Subscribe to:
     * - entityService.on_ready: Init all the Entity Filters.
     */
    private _registerChanges(): void {
        this.subscriptions.push(
            this.entityService.on_ready.subscribe((data: any) => {
                if (data) {
                    // Hotels
                    const _hotels = this.entityService.getEntities();
                    const _hotelFilter = new DashboardFilter(
                        1,
                        'hotel',
                        'Hotel',
                        'id',
                        'id',
                        _hotels,
                        'filter-column-large',
                        this._onHTLCDChange.bind(this),
                        0
                    );
                    _hotelFilter.searchExpr = ['id', 'name', 'EMMA_CODE'];
                    this.entityMapFilters.set('hotel', _hotelFilter);
                    // OpsAreas && Countries
                    let _opsArea: any[] = [];
                    let _countries: any[] = [];
                    let _armZone: any[] = [];
                    let _drmZone: any[] = [];
                    let _dop: any[] = [];
                    let _hotelPlace: any[] = [];
                    let _rrFsm: any[] = [];
                    let _stars: any[] = [];
                    let _pms: any[] = [];
                    if (environment.company === 'lhg') {
                        _opsArea = this.entityService.getOpsArea();
                        _countries = this.entityService.getCountry();
                        _armZone = this.entityService.getArmZone();
                        _drmZone = this.entityService.getDrmZone();
                        _dop = this.entityService.getDop();
                        _hotelPlace = this.entityService.getHotelPlace();
                        _rrFsm = this.entityService.getRRFsm();
                        _stars = this.entityService.getStars();
                        _pms = this.entityService.getPms();
                    } else {
                        const _opsAreaIds: number[] = [];
                        const _localisations = this.entityService.getLocalisations(true);
                        for (const x of _localisations) {
                            if (_opsAreaIds.indexOf(+x.parent) === -1) {
                                let _name = undefined;
                                switch (x.parent) {
                                    case 1:
                                        _name = 'CESE';
                                        break;
                                    case 2:
                                        _name = 'EERUT';
                                        break;
                                    case 3:
                                        _name = 'MEA';
                                        break;
                                    case 4:
                                        _name = 'NOB';
                                        break;
                                    case 5:
                                        _name = 'UKIRWE';
                                        break;
                                    case 6:
                                        _name = 'SAS';
                                        break;
                                    case 7:
                                        _name = 'SEAP';
                                        break;
                                    case 8:
                                        _name = 'China';
                                        break;
                                    case 9:
                                        _name = 'CESEE';
                                        break;
                                    case 10:
                                        _name = 'NOWE';
                                        break;
                                }
                                _opsAreaIds.push(+x.parent);
                                _opsArea.push({ id: x.parent, label: _name });
                            }
                            _countries.push({ id: x.id, label: x.label });
                        }
                    }

                    const _opsAreaFilter = new DashboardFilter(
                        1,
                        'opsarea',
                        'Ops Area',
                        'label',
                        'label',
                        _opsArea,
                        environment.company === 'rhg'
                            ? 'filter-column-medium-fixed-165'
                            : 'filter-column-200-fixed',
                        this._onOpsAreaChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('opsarea', _opsAreaFilter);
                    // Prev Ops Area
                    const _prevOpsArea = this.entityService.getPrevOpsArea();
                    const _prevOpsAreaFilter = new DashboardFilter(
                        1,
                        'prevopsarea',
                        'Old OpsArea',
                        'label',
                        'label',
                        _prevOpsArea,
                        'filter-column-medium-fixed-165',
                        this._onPrevOpsAreaChange.bind(this),
                        0
                    );
                    _prevOpsAreaFilter.hidden = true;
                    this.entityMapFilters.set('prevopsarea', _prevOpsAreaFilter);
                    // MD Area
                    const _mdArea = this.entityService.getMdArea();
                    const _mdAreaFilter = new DashboardFilter(
                        1,
                        'md_area',
                        'MD Area',
                        'label',
                        'label',
                        _mdArea,
                        'filter-column-medium-fixed-165',
                        this._onMdAreaChange.bind(this),
                        0
                    );
                    _mdAreaFilter.hidden = true;
                    this.entityMapFilters.set('md_area', _mdAreaFilter);
                    // Countries
                    const _countryFilter = new DashboardFilter(
                        1,
                        'country',
                        'Country',
                        'label',
                        'label',
                        _countries,
                        'filter-column-medium-fixed',
                        this._onCountryChange.bind(this),
                        0
                    );
                    _countryFilter.searchExpr = ['label'];
                    this.entityMapFilters.set('country', _countryFilter);
                    // Brands
                    const _brands = this.entityService.getBrands(true);
                    const _brandFilter = new DashboardFilter(
                        1,
                        'brand',
                        'Brand',
                        'label',
                        'label',
                        _brands,
                        'filter-column-medium',
                        this._onBrandChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('brand', _brandFilter);
                    // Ownerships
                    const _ownerships = this.entityService.getOwnerships(true);
                    const _contractFilter = new DashboardFilter(
                        1,
                        'contract',
                        'Contract',
                        'label',
                        'label',
                        _ownerships,
                        'filter-column-medium-fixed',
                        this._onContractChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('contract', _contractFilter);
                    // Perimeter Grp
                    const _perimeterGrp = this.entityService.getPerimeterGrp(true);
                    const _perimeterGrpFilter = new DashboardFilter(
                        1,
                        'perimeter_grp',
                        'Perim Grp ',
                        'label',
                        'label',
                        _perimeterGrp,
                        'filter-column-medium-fixed',
                        this._onPerimeterGrpChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('perimeter_grp', _perimeterGrpFilter);
                    // Perimeter 5YP
                    const _perimeter5yp = this.entityService.getPerimeter5YP(true);
                    const _perimeter5ypFilter = new DashboardFilter(
                        1,
                        'perimeter_5yp',
                        'Perim 5YP ',
                        'label',
                        'label',
                        _perimeter5yp,
                        'filter-column-medium-fixed',
                        this._onPerimeter5ypChange.bind(this),
                        0
                    );
                    _perimeter5ypFilter.hidden = true;
                    this.entityMapFilters.set('perimeter_5yp', _perimeter5ypFilter);
                    // RevArea
                    const _revAreas = this.entityService.getRevArea();
                    const _revAreaFilter = new DashboardFilter(
                        1,
                        'revarea',
                        'Rev Area',
                        'label',
                        'label',
                        _revAreas,
                        environment.company === 'rhg'
                            ? 'filter-column-medium-fixed-165'
                            : 'filter-column-200-fixed',
                        this._onRevAreaChange.bind(this),
                        0
                    );
                    _revAreaFilter.hidden = true;
                    this.entityMapFilters.set('revarea', _revAreaFilter);
                    // RMC FLAG
                    const _rmcFlags = [
                        { id: 'Y', label: 'Y' },
                        { id: 'N', label: 'N' }
                    ];
                    const _rmcFlagFilter = new DashboardFilter(
                        1,
                        'rmc_flag',
                        'RMC Flag',
                        'label',
                        'label',
                        _rmcFlags,
                        'filter-column-small',
                        this._onRmcFlagChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('rmc_flag', _rmcFlagFilter);
                    // CC outsourced FLAG
                    const _ccflags = [
                        { id: 'Y', label: 'Y' },
                        { id: 'N', label: 'N' }
                    ];
                    const _ccFlagFilter = new DashboardFilter(
                        1,
                        'cc',
                        'CC Outscrcd',
                        'label',
                        'label',
                        _ccflags,
                        'filter-column-small',
                        this._onCCFlagChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('cc', _ccFlagFilter);
                    // Focus FLAG
                    const _focusflags = [
                        { id: '1', label: 'Y' },
                        { id: '0', label: 'N' }
                    ];
                    const _focusFlagFilter = new DashboardFilter(
                        1,
                        'focus',
                        'Focus',
                        'id',
                        'label',
                        _focusflags,
                        'filter-column-small',
                        this._onFocusFlagChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('focus', _focusFlagFilter);
                    // City
                    const _cities = this.entityService.getCities();
                    const _cityFilter = new DashboardFilter(
                        1,
                        'city',
                        'City',
                        'label',
                        'label',
                        _cities,
                        environment.company === 'rhg'
                            ? 'filter-column-medium-fixed'
                            : 'filter-column-200-fixed',
                        this._onCityChange.bind(this),
                        0
                    );
                    _cityFilter.searchExpr = ['label'];
                    if (environment.company === 'lhg') {
                        _cityFilter.hidden = true;
                    }
                    this.entityMapFilters.set('city', _cityFilter);
                    // # of Rooms
                    const _rooms = this.entityService.getRooms();
                    const _roomsFilter = new DashboardFilter(
                        1,
                        'room_count',
                        'Room Count',
                        'label',
                        'label',
                        _rooms,
                        'filter-column-medium-fixed',
                        this._onRoomsChange.bind(this),
                        0
                    );
                    if (environment.company === 'lhg') {
                        _roomsFilter.hidden = true;
                    }
                    this.entityMapFilters.set('room_count', _roomsFilter);
                    // Theatre
                    const _theatres = this.entityService.getTheatres();
                    const _theatreFilter = new DashboardFilter(
                        1,
                        'theatre',
                        'Theatre',
                        'label',
                        'label',
                        _theatres,
                        environment.company === 'rhg'
                            ? 'filter-column-190-fixed'
                            : 'filter-column-190-fixed',
                        this._onTheatreChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('theatre', _theatreFilter);
                    /* if (_theatres.length === 1) {
                        this.hotelListHeigth = 250;
                    } */
                    // Set header Functions
                    // Areas

                    // Perimeters
                    _perimeterGrpFilter.headerF = (): void => {
                        _perimeterGrpFilter.hidden = true;
                        _perimeter5ypFilter.hidden = false;
                    };
                    _perimeterGrpFilter.headerIco = 'fa fa-refresh';
                    _perimeterGrpFilter.tooltip = 'Switch to 5YP Perimeter';
                    _perimeter5ypFilter.headerF = (): void => {
                        _perimeter5ypFilter.hidden = true;
                        _perimeterGrpFilter.hidden = false;
                    };
                    _perimeter5ypFilter.headerIco = 'fa fa-refresh';
                    _perimeter5ypFilter.tooltip = 'Switch to Per';
                    // Hotel Type
                    const _hotel_type = this.entityService.getHotelType();
                    const _hotel_type_Filter = new DashboardFilter(
                        1,
                        'hotel_type',
                        'Hotel Type',
                        'id',
                        'label',
                        _hotel_type,
                        'filter-column-medium-fixed',
                        this._onHotelTypeChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('hotel_type', _hotel_type_Filter);
                    // Market Type
                    const _market_type = this.entityService.getMarketType();
                    const _market_type_Filter = new DashboardFilter(
                        1,
                        'market_type',
                        'Market Type',
                        'id',
                        'label',
                        _market_type,
                        'filter-column-medium-fixed',
                        this._onMarketTypeChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('market_type', _market_type_Filter);
                    // ME Cluster
                    const _me_cluster = this.entityService.getMECluster();
                    const _me_cluster_Filter = new DashboardFilter(
                        1,
                        'me_cluster',
                        'M&E Clubs',
                        'id',
                        'label',
                        _me_cluster,
                        'filter-column-medium-fixed',
                        this._onMEClusterChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('me_cluster', _me_cluster_Filter);
                    // STATUS
                    const _status = this.entityService.getStatus();
                    const _statusFilter = new DashboardFilter(
                        1,
                        'status',
                        'Status',
                        'label',
                        'label',
                        _status,
                        'filter-column-medium-fixed',
                        this._onStatusChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('status', _statusFilter);
                    // ARM ZONE
                    const _armZoneFilter = new DashboardFilter(
                        1,
                        'arm_zone',
                        'ARM Zone',
                        'label',
                        'label',
                        _armZone,
                        'filter-column-200-fixed',
                        this._onArmChange.bind(this),
                        0
                    );
                    _armZoneFilter.hidden = true;
                    this.entityMapFilters.set('arm_zone', _armZoneFilter);
                    // DRM ZONE
                    const _drmZoneFilter = new DashboardFilter(
                        1,
                        'drm_zone',
                        'DRM Zone',
                        'label',
                        'label',
                        _drmZone,
                        'filter-column-200-fixed',
                        this._onDrmChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('drm_zone', _drmZoneFilter);
                    // DOP
                    const _dopFilter = new DashboardFilter(
                        1,
                        'dop',
                        'DOP',
                        'label',
                        'label',
                        _dop,
                        'filter-column-200-fixed',
                        this._onDopChange.bind(this),
                        0
                    );
                    _dopFilter.hidden = true;
                    this.entityMapFilters.set('dop', _dopFilter);
                    // HOTEL PLACE
                    const _hotelPlaceFilter = new DashboardFilter(
                        1,
                        'hotel_place',
                        'Place Group',
                        'label',
                        'label',
                        _hotelPlace,
                        'filter-column-200-fixed',
                        this._onHotelPlaceChange.bind(this),
                        0
                    );
                    _hotelPlaceFilter.searchExpr = ['label'];
                    this.entityMapFilters.set('hotel_place', _hotelPlaceFilter);
                    // RR FSM
                    const _rrFsmFilter = new DashboardFilter(
                        1,
                        'rr_fsm',
                        'RR/FSM',
                        'label',
                        'label',
                        _rrFsm,
                        'filter-column-200-fixed',
                        this._onRrFsmChange.bind(this),
                        0
                    );
                    _rrFsmFilter.hidden = true;
                    this.entityMapFilters.set('rr_fsm', _rrFsmFilter);
                    // Stars
                    const _starsFilter = new DashboardFilter(
                        1,
                        'stars',
                        'Stars',
                        'label',
                        'label',
                        _stars,
                        'filter-column-200-fixed',
                        this._onStarshange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('stars', _starsFilter);
                    // Pms
                    const _pmsFilter = new DashboardFilter(
                        1,
                        'pms',
                        'PMS',
                        'label',
                        'label',
                        _pms,
                        'filter-column-200-fixed',
                        this._onPmsChange.bind(this),
                        0
                    );
                    this.entityMapFilters.set('pms', _pmsFilter);

                    if (environment.company === 'lhg') {
                        _opsAreaFilter.headerF = (): void => {
                            _opsAreaFilter.hidden = true;
                            _dopFilter.hidden = false;
                            _rrFsmFilter.hidden = true;
                        };
                        _opsAreaFilter.headerIco = 'fa fa-refresh';
                        _opsAreaFilter.tooltip = 'Switch to DOP';

                        _dopFilter.headerF = (): void => {
                            _opsAreaFilter.hidden = true;
                            _dopFilter.hidden = true;
                            _rrFsmFilter.hidden = false;
                        };
                        _dopFilter.headerIco = 'fa fa-refresh';
                        _dopFilter.tooltip = 'Switch to RR/FSM';

                        _rrFsmFilter.headerF = (): void => {
                            _opsAreaFilter.hidden = false;
                            _dopFilter.hidden = true;
                            _rrFsmFilter.hidden = true;
                        };
                        _rrFsmFilter.headerIco = 'fa fa-refresh';
                        _rrFsmFilter.tooltip = 'Switch to Ops Area';

                        _hotelPlaceFilter.headerF = (): void => {
                            _hotelPlaceFilter.hidden = true;
                            _cityFilter.hidden = false;
                        };
                        _cityFilter.headerIco = 'fa fa-refresh';
                        _cityFilter.tooltip = 'Switch to Place Group';

                        _cityFilter.headerF = (): void => {
                            _hotelPlaceFilter.hidden = false;
                            _cityFilter.hidden = true;
                        };
                        _hotelPlaceFilter.headerIco = 'fa fa-refresh';
                        _hotelPlaceFilter.tooltip = 'Switch to City';

                        _drmZoneFilter.headerF = (): void => {
                            _drmZoneFilter.hidden = true;
                            _armZoneFilter.hidden = false;
                        };
                        _armZoneFilter.headerIco = 'fa fa-refresh';
                        _armZoneFilter.tooltip = 'Switch to DRM Zone';

                        _armZoneFilter.headerF = (): void => {
                            _drmZoneFilter.hidden = false;
                            _armZoneFilter.hidden = true;
                        };
                        _drmZoneFilter.headerIco = 'fa fa-refresh';
                        _drmZoneFilter.tooltip = 'Switch to ARM Zone';

                        _hotel_type_Filter.headerF = (): void => {
                            _hotel_type_Filter.hidden = true;
                            _roomsFilter.hidden = false;
                        };
                        _roomsFilter.headerIco = 'fa fa-refresh';
                        _roomsFilter.tooltip = 'Switch to Room Count Filter';

                        _roomsFilter.headerF = (): void => {
                            _hotel_type_Filter.hidden = false;
                            _roomsFilter.hidden = true;
                        };
                        _hotel_type_Filter.headerIco = 'fa fa-refresh';
                        _hotel_type_Filter.tooltip = 'Switch to Hotel Type Filter';
                    } else {
                        _opsAreaFilter.headerF = (): void => {
                            _opsAreaFilter.hidden = true;
                            _revAreaFilter.hidden = false;
                            _prevOpsAreaFilter.hidden = true;
                            _mdAreaFilter.hidden = true;
                        };
                        _opsAreaFilter.headerIco = 'fa fa-refresh';
                        _opsAreaFilter.tooltip = 'Switch to Rev Area';

                        _revAreaFilter.headerF = (): void => {
                            _revAreaFilter.hidden = true;
                            _opsAreaFilter.hidden = true;
                            _prevOpsAreaFilter.hidden = false;
                            _mdAreaFilter.hidden = true;
                        };
                        _revAreaFilter.headerIco = 'fa fa-refresh';
                        _revAreaFilter.tooltip = 'Switch to Previous Ops Area';

                        _prevOpsAreaFilter.headerF = (): void => {
                            _revAreaFilter.hidden = true;
                            _opsAreaFilter.hidden = true;
                            _prevOpsAreaFilter.hidden = true;
                            _mdAreaFilter.hidden = false;
                        };
                        _prevOpsAreaFilter.headerIco = 'fa fa-refresh';
                        _prevOpsAreaFilter.tooltip = 'Switch to MD Area';

                        _mdAreaFilter.headerF = (): void => {
                            _revAreaFilter.hidden = true;
                            _opsAreaFilter.hidden = false;
                            _prevOpsAreaFilter.hidden = true;
                            _mdAreaFilter.hidden = true;
                        };
                        _mdAreaFilter.headerIco = 'fa fa-refresh';
                        _mdAreaFilter.tooltip = 'Switch to Ops Area';
                    }

                    this.entitiesResolve();
                }
            })
        );
        this.eventManagerService.subscribe('dashboardSwitch', (response) => {
            this.allowedHtlIds = response.data;
            //this.dashboardService.dxListFiltersMap.get('hotel').ngAfterViewInit();
        });
    }
    /**
     * Update the dxComponents selectedItemKeys of the entity filters with the selected nodes on entityService.
     * If the dxComponent doesn't exists on 'hotel' filter, update the DashboardFilter and the array 'selectedsHotelsNotDx'
     */
    private _updateSelectedsEntityFilters(): void {
        this.dxMapPromise.then(() => {
            this._disableDxComponents();
            // Hotels
            const _dxHotel = this.dashboardService.dxListFiltersMap.get('hotel');
            if (_dxHotel) {
                _dxHotel.selectedItemKeys = [
                    ...new Set(this.entityService.selectedEntityIds)
                ];
                this.entityMapFilters
                    .get('hotel')
                    .saveKeysBackup(this.entityService.selectedEntityIds);
            } else {
                this.entityMapFilters.get('hotel').selectedKeys = [
                    ...new Set(this.entityService.selectedEntityIds)
                ];
                this.selectedsHotelsNotDx = [
                    ...new Set(this.entityService.selectedEntities)
                ];
            }
            // OpsArea
            const _dxOpsArea = this.dashboardService.dxListFiltersMap.get('opsarea');
            if (_dxOpsArea) {
                _dxOpsArea.selectedItemKeys = [
                    ...new Set(this.entityService.selectedRegionNotFully)
                ];
                this.entityMapFilters
                    .get('opsarea')
                    .saveKeysBackup(this.entityService.selectedRegionNotFully);
            }
            // PreVOpsArea
            const _dxPrevOpsArea =
                this.dashboardService.dxListFiltersMap.get('prevopsarea');
            if (_dxPrevOpsArea) {
                _dxPrevOpsArea.selectedItemKeys = [
                    ...new Set(this.entityService.selectedPrevOpsArea)
                ];
                this.entityMapFilters
                    .get('prevopsarea')
                    .saveKeysBackup(this.entityService.selectedPrevOpsArea);
            }
            // MDAarea
            const _dxMdArea = this.dashboardService.dxListFiltersMap.get('md_area');
            if (_dxMdArea) {
                _dxMdArea.selectedItemKeys = [
                    ...new Set(this.entityService.selectedMdArea)
                ];
                this.entityMapFilters
                    .get('md_area')
                    .saveKeysBackup(this.entityService.selectedMdArea);
            }
            // Country
            const _dxCountry = this.dashboardService.dxListFiltersMap.get('country');
            if (_dxCountry) {
                _dxCountry.selectedItemKeys = [
                    ...new Set(this.entityService.selectedCountry)
                ];
                this.entityMapFilters
                    .get('country')
                    .saveKeysBackup(this.entityService.selectedCountry);
            }
            // Brand
            const _dxBrand = this.dashboardService.dxListFiltersMap.get('brand');
            if (_dxBrand) {
                _dxBrand.selectedItemKeys = [
                    ...new Set(this.entityService.selectedBrand)
                ];
                this.entityMapFilters
                    .get('brand')
                    .saveKeysBackup(this.entityService.selectedBrand);
            }
            // Contract
            const _dxContract = this.dashboardService.dxListFiltersMap.get('contract');
            if (_dxContract) {
                _dxContract.selectedItemKeys = [
                    ...new Set(this.entityService.selectedContract)
                ];
                this.entityMapFilters
                    .get('contract')
                    .saveKeysBackup(this.entityService.selectedContract);
            }
            // Perimeter Grp
            const _dxPerimeterGrp =
                this.dashboardService.dxListFiltersMap.get('perimeter_grp');
            if (_dxPerimeterGrp) {
                _dxPerimeterGrp.selectedItemKeys = [
                    ...new Set(this.entityService.selectedPerimeterGrp)
                ];
                this.entityMapFilters
                    .get('perimeter_grp')
                    .saveKeysBackup(this.entityService.selectedPerimeterGrp);
            }
            // Perimeter 5yp
            const _dxPerimeter5yp =
                this.dashboardService.dxListFiltersMap.get('perimeter_5yp');
            if (_dxPerimeter5yp) {
                _dxPerimeter5yp.selectedItemKeys = [
                    ...new Set(this.entityService.selectedPerimeter5yp)
                ];
                this.entityMapFilters
                    .get('perimeter_5yp')
                    .saveKeysBackup(this.entityService.selectedPerimeter5yp);
            }
            // RevArea
            const _dxRevArea = this.dashboardService.dxListFiltersMap.get('revarea');
            if (_dxRevArea) {
                _dxRevArea.selectedItemKeys = [
                    ...new Set(this.entityService.selectedRevArea)
                ];
                this.entityMapFilters
                    .get('revarea')
                    .saveKeysBackup(this.entityService.selectedRevArea);
            }
            // RmcFlag
            const _dxRmcFlag = this.dashboardService.dxListFiltersMap.get('rmc_flag');
            if (_dxRmcFlag) {
                _dxRmcFlag.selectedItemKeys = [
                    ...new Set(this.entityService.selectedRmcFlag)
                ];
                this.entityMapFilters
                    .get('rmc_flag')
                    .saveKeysBackup(this.entityService.selectedRmcFlag);
            }
            // City
            const _dxCity = this.dashboardService.dxListFiltersMap.get('city');
            if (_dxCity) {
                _dxCity.selectedItemKeys = [
                    ...new Set(this.entityService.selectedCities)
                ];
                this.entityMapFilters
                    .get('city')
                    .saveKeysBackup(this.entityService.selectedCities);
            }
            // Rooms
            const _dxRoom = this.dashboardService.dxListFiltersMap.get('room_count');
            if (_dxRoom) {
                _dxRoom.selectedItemKeys = [...new Set(this.entityService.selectedRooms)];
                this.entityMapFilters
                    .get('room_count')
                    .saveKeysBackup(this.entityService.selectedRooms);
            }
            // Theatre
            const _dxTheatre = this.dashboardService.dxListFiltersMap.get('theatre');
            if (_dxTheatre) {
                _dxTheatre.selectedItemKeys = [
                    ...new Set(this.entityService.selectedTheatre)
                ];
                this.entityMapFilters
                    .get('theatre')
                    .saveKeysBackup(this.entityService.selectedTheatre);
            }
            // ccFlag
            const _dxCCFlag = this.dashboardService.dxListFiltersMap.get('cc');
            if (_dxCCFlag) {
                _dxCCFlag.selectedItemKeys = [
                    ...new Set(this.entityService.selectedCCFlag)
                ];
                this.entityMapFilters
                    .get('cc')
                    .saveKeysBackup(this.entityService.selectedCCFlag);
            }
            // focusFlag
            const _dxFocusFlag = this.dashboardService.dxListFiltersMap.get('focus');
            if (_dxFocusFlag) {
                _dxFocusFlag.selectedItemKeys = [
                    ...new Set(this.entityService.selectedFocusFlag)
                ];
                this.entityMapFilters
                    .get('focus')
                    .saveKeysBackup(this.entityService.selectedFocusFlag);
            }
            // Hotel Type
            const _dxHtlType = this.dashboardService.dxListFiltersMap.get('hotel_type');
            if (_dxHtlType) {
                _dxHtlType.selectedItemKeys = [
                    ...new Set(this.entityService.selectedHotelType)
                ];
                this.entityMapFilters
                    .get('hotel_type')
                    .saveKeysBackup(this.entityService.selectedHotelType);
            }
            // Market Type
            const _dxMarketType =
                this.dashboardService.dxListFiltersMap.get('market_type');
            if (_dxMarketType) {
                _dxMarketType.selectedItemKeys = [
                    ...new Set(this.entityService.selectedMarketType)
                ];
                this.entityMapFilters
                    .get('market_type')
                    .saveKeysBackup(this.entityService.selectedMarketType);
            }
            this._enableDxComponents();
            this._onHTLCDChange();
            // M&E Cluster
            const _dxMECluster = this.dashboardService.dxListFiltersMap.get('me_cluster');
            if (_dxMECluster) {
                _dxMECluster.selectedItemKeys = [
                    ...new Set(this.entityService.selectedMECluster)
                ];
                this.entityMapFilters
                    .get('me_cluster')
                    .saveKeysBackup(this.entityService.selectedMECluster);
            }
            this._enableDxComponents();
            this._onHTLCDChange();
            // Stars
            const _dxStars = this.dashboardService.dxListFiltersMap.get('stars');
            if (_dxStars) {
                _dxStars.selectedItemKeys = [
                    ...new Set(this.entityService.selectedStars)
                ];
                this.entityMapFilters
                    .get('stars')
                    .saveKeysBackup(this.entityService.selectedStars);
            }
            // PMS
            const _dxPms = this.dashboardService.dxListFiltersMap.get('pms');
            if (_dxPms) {
                _dxPms.selectedItemKeys = [...new Set(this.entityService.selectedPms)];
                this.entityMapFilters
                    .get('pms')
                    .saveKeysBackup(this.entityService.selectedPms);
            }
        });
    }
    /**
     * Disable all the dx components except the given one
     * @param filterNode
     */
    private _disableDxComponents(filterNode?: DashboardFilter): void {
        this.dashboardService.filtersMap.forEach((f: DashboardFilter, _key: string) => {
            f.enabled = filterNode ? f.id === filterNode.id : false;
        });
    }
    /**
     * Enable all the dx components
     * Workaround, trying to find better solution
     */
    private _enableDxComponents(): void {
        setTimeout(() => {
            // Workaround, trying to find better solution
            this.dashboardService.filtersMap.forEach(
                (f: DashboardFilter, _key: string) => {
                    f.enabled = true;
                }
            );
        }, 100);
    }
    /**
     * The entity filters are displayed as dxList but they should have the functionality
     * to be checked as undetermined like in dxTree (which it's not possible).
     * So, as a workaround, when a entity filter it's checked, the binded ones will be checked as well,
     * doesn't matter if there are more options of the binded ones which remain unchecked, but, if the
     * selectAll of anyone of this entity filters it's checked/unchecked, then, we will select/unselect
     * all the nodes of every other entity filter as well.
     * @param filter
     */
    private _onForceAllEntities(filter: DashboardFilter): void {
        const _hotels: any[] = this.forceAllEntities
            ? this.entityService.getEntities()
            : [];
        const _hotelsKeys: string[] = _hotels.map((node) => node.id);
        const _dxComponent = this.dashboardService.dxListFiltersMap.get('hotel');
        if (_dxComponent) {
            _dxComponent.selectedItemKeys = _hotelsKeys;
        } else {
            const _hotelFilter = this.entityMapFilters.get('hotel');
            _hotelFilter.selectedKeys = _hotelsKeys;
            this.selectedsHotelsNotDx = _hotels;
        }
        switch (filter.id) {
            case 'hotel':
                this._updateEntitiesFilters(_hotels);
                break;
            case 'opsarea':
                this._updateEntitiesFilters(_hotels, true);
                break;
            case 'country':
                this._updateEntitiesFilters(_hotels, false, true);
                break;
            case 'brand':
                this._updateEntitiesFilters(_hotels, false, false, true);
                break;
            case 'contract':
                this._updateEntitiesFilters(_hotels, false, false, false, true);
                break;
            case 'revarea':
                this._updateEntitiesFilters(_hotels, false, false, false, false, true);
                break;
            case 'rmc_flag':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'city':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'source': // not an entity filter
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'room_count':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'theatre':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'perimeter_grp':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'perimeter_5yp':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'cc':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'prevopsarea':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'focus':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'stars':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
            case 'pms':
                this._updateEntitiesFilters(
                    _hotels,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                );
                break;
        }
    }
    /**
     * Update an HTLCD filter. It's needed to change the binded filters.
     * @param ev
     * @param filterNode
     */
    private _onHTLCDChange(): void {
        if (this.dashboardService.dxListFiltersMap.get('hotel')) {
            this._updateEntitiesFilters(
                this.dashboardService.dxListFiltersMap.get('hotel').selectedItems
            );
        }
    }
    private _onOpsAreaChange(ev: any): void {
        let entitiesToAdd: any[] = [];
        let entitiesToRemove: any[] = [];
        if (environment.company === 'rhg') {
            const _addedCountries = this._getListFromMap(
                ev.addedItems,
                'id',
                this.entityService.mapOpsAreaEntities,
                true
            );
            const _removedCountries = this._getListFromMap(
                ev.removedItems,
                'id',
                this.entityService.mapOpsAreaEntities,
                true
            );
            entitiesToAdd = this._getListFromMap(
                _addedCountries,
                undefined,
                this.entityService.mapCountriesEntities
            );
            entitiesToRemove = this._getListFromMap(
                _removedCountries,
                undefined,
                this.entityService.mapCountriesEntities
            );
        } else {
            entitiesToAdd = this._getListFromMap(
                ev.addedItems,
                'id',
                this.entityService.mapOpsAreaEntities
            );
            entitiesToRemove = this._getListFromMap(
                ev.removedItems,
                'id',
                this.entityService.mapOpsAreaEntities
            );
        }
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(_hotels, true);
    }
    private _onPrevOpsAreaChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapPrevOpsAreaEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapPrevOpsAreaEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onStatusChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapStatusEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapStatusEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }

    private _onMdAreaChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapMdAreaEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapMdAreaEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onArmChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapArmZoneEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapArmZoneEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onDrmChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapDrmZoneEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapDrmZoneEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onDopChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapDopEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapDopEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onHotelPlaceChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapHotelPlaceEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapHotelPlaceEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onRrFsmChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapRRFsmEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapRRFsmEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }

    private _onStarshange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapStarsEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapStarsEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }

    private _onPmsChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapPmsEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapPmsEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }

    private _onCountryChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapCountriesEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapCountriesEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(_hotels, false, true);
    }
    private _onBrandChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapBrandEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapBrandEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(_hotels, false, false, true);
    }
    private _onContractChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapOwnershipEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapOwnershipEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(_hotels, false, false, false, true);
    }
    private _onRevAreaChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapRevAreaEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapRevAreaEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(_hotels, false, false, false, false, true);
    }
    private _onRmcFlagChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapRmcFlagEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapRmcFlagEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(_hotels, false, false, false, false, false, true);
    }
    private _onCCFlagChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapCCFlagEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapCCFlagEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onFocusFlagChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapFocusFlagEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapFocusFlagEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onCityChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapCitiesEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapCitiesEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onRoomsChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapRoomsEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapRoomsEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onTheatreChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapOpTheatreEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapOpTheatreEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onPerimeterGrpChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'label',
            this.entityService.mapPerimeterGrpEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'label',
            this.entityService.mapPerimeterGrpEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onPerimeter5ypChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'label',
            this.entityService.mapPerimeter5ypEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'label',
            this.entityService.mapPerimeter5ypEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onHotelTypeChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapHotelTypeEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapHotelTypeEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onMarketTypeChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapMarketTypeEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapMarketTypeEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _onMEClusterChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapMEClusterEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapMEClusterEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    /**
     * Not an entity filter
     * @param ev
     */
    public onSourceChange(ev: any): void {
        const entitiesToAdd = this._getListFromMap(
            ev.addedItems,
            'id',
            this.entityService.mapSourceEntities
        );
        const entitiesToRemove = this._getListFromMap(
            ev.removedItems,
            'id',
            this.entityService.mapSourceEntities
        );
        const _hotels = this._getUpdatedHotels(entitiesToAdd, entitiesToRemove);
        this._updateEntitiesFilters(
            _hotels,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true
        );
    }
    private _getUpdatedHotels(
        entitiesToAdd: string[],
        entitiesToRemove: string[]
    ): any[] {
        const _dxComponent = this.dashboardService.dxListFiltersMap.get('hotel');
        let _hotels;
        if (_dxComponent) {
            _hotels = this._updateDxComponentKeys(
                _dxComponent,
                entitiesToAdd,
                entitiesToRemove
            );
        } else {
            _hotels = this._updateSelectedHotelsNotDx(entitiesToAdd, entitiesToRemove);
        }
        return _hotels;
    }
    /**
     * Update the keys of the dx-list component
     * @param dxComponent
     * @param entitiesToAdd
     * @param entitiesToRemove
     */
    private _updateDxComponentKeys(
        dxComponent: DxListComponent,
        entitiesToAdd: string[],
        entitiesToRemove: string[]
    ): any[] {
        if (entitiesToAdd.length) {
            const _keys = [...new Set(dxComponent.selectedItemKeys)];
            dxComponent.selectedItemKeys = _keys.concat(entitiesToAdd);
        } else if (entitiesToRemove.length) {
            let _keys = [...new Set(dxComponent.selectedItemKeys)];
            for (const ent of entitiesToRemove) {
                _keys = _keys.filter((entity) => entity !== ent);
            }
            dxComponent.selectedItemKeys = _keys;
        }
        return dxComponent.selectedItems;
    }
    /**
     * Update the selectedsHotelsNotDx array and the selectedKeys from 'hotel' filter
     * @param entitiesToAdd
     * @param entitiesToRemove
     */
    private _updateSelectedHotelsNotDx(
        entitiesToAdd: string[],
        entitiesToRemove: string[]
    ): any[] {
        const _hotelFilter = this.entityMapFilters.get('hotel');
        if (entitiesToAdd.length) {
            _hotelFilter.selectedKeys = _hotelFilter.selectedKeys.concat(entitiesToAdd);
            // Add entities to selectedHotelsNotDx array
            const _totalEntities = this.entityService.getEntities();
            const _entitiesToAdd = _totalEntities.filter(
                (ent) => entitiesToAdd.indexOf(ent.id) !== -1
            );
            this.selectedsHotelsNotDx = this.selectedsHotelsNotDx.concat(_entitiesToAdd);
        } else if (entitiesToRemove.length) {
            for (const ent of entitiesToRemove) {
                _hotelFilter.selectedKeys = _hotelFilter.selectedKeys.filter(
                    (entity) => entity !== ent
                );
            }
            // Remove entities to selectedHotelsNotDx array
            for (const ent of entitiesToRemove) {
                this.selectedsHotelsNotDx = this.selectedsHotelsNotDx.filter(
                    (entity) => entity.id !== ent
                );
            }
        }
        return this.selectedsHotelsNotDx;
    }
    private _getListFromMap(
        list: any[],
        key: string,
        map: Map<any, any[]>,
        isRegion?: boolean
    ): any[] {
        const _keys = [];
        if (list) {
            for (const x of list) {
                const _entities = map.get(key ? x[key] : x);
                if (_entities) {
                    for (const y of _entities) {
                        const _id = isRegion ? y : y.id;
                        if (_keys.indexOf(_id) === -1) {
                            _keys.push(_id);
                        }
                    }
                }
            }
        }
        return _keys;
    }
    /**
     * Every entity filter will call this method on the onChange Function
     * @param hotels
     * @param avoidOpsArea
     * @param avoidCountries
     * @param avoidBrands
     * @param avoidOwnerships
     * @param avoidComparables
     * @param avoidRevAreas
     * @param avoidRmcFlags
     * @param avoidCities
     * @param avoidSources (not an entity filter)
     * @param avoidRooms
     * @param avoidTheatres
     * @param avoidComparablesNy
     * @param avoidComparablesLy
     * @param avoidPerimeterGrp
     * @param avoidPerimeter5yp
     * @param avoidHotelType
     * @param avoidMarketType
     */
    private _updateEntitiesFilters(
        hotels: any[],
        avoidOpsArea?: boolean,
        avoidCountries?: boolean,
        avoidBrands?: boolean,
        avoidOwnerships?: boolean,
        avoidRevAreas?: boolean,
        avoidRmcFlags?: boolean,
        avoidCities?: boolean,
        avoidSources?: boolean,
        avoidRooms?: boolean,
        avoidTheatres?: boolean,
        avoidPerimeterGrp?: boolean,
        avoidPerimeter5yp?: boolean,
        avoidCCFlags?: boolean,
        avoidHotelType?: boolean,
        avoidMarketType?: boolean,
        avoidMECluster?: boolean,
        avoidPrevOpsArea?: boolean,
        avoidFocusFlags?: boolean,
        avoidMdArea?: boolean,
        avoidStatus?: boolean,
        avoidArm?: boolean,
        avoidDrm?: boolean,
        avoidDop?: boolean,
        avoidHotelPlace?: boolean,
        avoidRRFsm?: boolean,
        avoidStars?: boolean,
        avoidPms?: boolean
    ): void {
        const _dxOpsArea = this.dashboardService.dxListFiltersMap.get('opsarea');
        const _opsArea = _dxOpsArea && !avoidOpsArea ? [] : undefined;
        const _dxCountry = this.dashboardService.dxListFiltersMap.get('country');
        const _countries = _dxCountry && !avoidCountries ? [] : undefined;
        const _dxBrand = this.dashboardService.dxListFiltersMap.get('brand');
        const _brands = _dxBrand && !avoidBrands ? [] : undefined;
        const _dxContract = this.dashboardService.dxListFiltersMap.get('contract');
        const _ownerships = _dxContract && !avoidOwnerships ? [] : undefined;
        const _dxPerimeterGrp =
            this.dashboardService.dxListFiltersMap.get('perimeter_grp');
        const _perimeterGrp = _dxPerimeterGrp && !avoidPerimeterGrp ? [] : undefined;
        const _dxPerimeter5yp =
            this.dashboardService.dxListFiltersMap.get('perimeter_5yp');
        const _perimeter5yp = _dxPerimeter5yp && !avoidPerimeter5yp ? [] : undefined;
        const _dxRevArea = this.dashboardService.dxListFiltersMap.get('revarea');
        const _revAreas = _dxRevArea && !avoidRevAreas ? [] : undefined;
        const _dxRmcFlag = this.dashboardService.dxListFiltersMap.get('rmc_flag');
        const _rmcFlags = _dxRmcFlag && !avoidRmcFlags ? [] : undefined;
        const _dxCity = this.dashboardService.dxListFiltersMap.get('city');
        const _cities = _dxCity && !avoidCities ? [] : undefined;
        const _dxSource = this.dashboardService.dxListFiltersMap.get('source');
        const _sources = _dxSource && !avoidSources ? [] : undefined;
        const _dxRoom = this.dashboardService.dxListFiltersMap.get('room_count'); //rooms
        const _rooms = _dxRoom && !avoidRooms ? [] : undefined;
        const _dxTheatre = this.dashboardService.dxListFiltersMap.get('theatre'); //theatre
        const _theatres = _dxTheatre && !avoidTheatres ? [] : undefined;
        const _dxCCFlag = this.dashboardService.dxListFiltersMap.get('cc');
        const _ccFlags = _dxCCFlag && !avoidCCFlags ? [] : undefined;
        const _dxHtlType = this.dashboardService.dxListFiltersMap.get('hotel_type');
        const _hotel_type = _dxHtlType && !avoidHotelType ? [] : undefined;
        const _dxMarketType = this.dashboardService.dxListFiltersMap.get('market_type');
        const _market_type = _dxMarketType && !avoidMarketType ? [] : undefined;
        const _dxMECluster = this.dashboardService.dxListFiltersMap.get('me_cluster');
        const _me_cluster = _dxMECluster && !avoidMECluster ? [] : undefined;
        const _dxPrevOpsArea = this.dashboardService.dxListFiltersMap.get('prevopsarea');
        const _prevOpsArea = _dxPrevOpsArea && !avoidPrevOpsArea ? [] : undefined;
        const _dxFocusFlag = this.dashboardService.dxListFiltersMap.get('focus');
        const _focusFlags = _dxFocusFlag && !avoidFocusFlags ? [] : undefined;
        const _dxMdArea = this.dashboardService.dxListFiltersMap.get('md_area');
        const _mdArea = _dxMdArea && !avoidMdArea ? [] : undefined;
        const _dxStatus = this.dashboardService.dxListFiltersMap.get('status');
        const _status = _dxStatus && !avoidStatus ? [] : undefined;
        const _dxArm = this.dashboardService.dxListFiltersMap.get('arm_zone');
        const _arm = _dxArm && !avoidArm ? [] : undefined;
        const _dxDrm = this.dashboardService.dxListFiltersMap.get('drm_zone');
        const _drm = _dxDrm && !avoidDrm ? [] : undefined;
        const _dxDop = this.dashboardService.dxListFiltersMap.get('dop');
        const _dop = _dxDop && !avoidDop ? [] : undefined;
        const _dxHotelPlace = this.dashboardService.dxListFiltersMap.get('hotel_place');
        const _hotelPlace = _dxHotelPlace && !avoidHotelPlace ? [] : undefined;
        const _dxRRFsm = this.dashboardService.dxListFiltersMap.get('rr_fsm');
        const _rrfsm = _dxRRFsm && !avoidRRFsm ? [] : undefined;
        const _dxStars = this.dashboardService.dxListFiltersMap.get('stars');
        const _stars = _dxStars && !avoidStars ? [] : undefined;
        const _dxPms = this.dashboardService.dxListFiltersMap.get('pms');
        const _pms = _dxPms && !avoidPms ? [] : undefined;

        // Fill arrays
        for (const h of hotels) {
            if (_opsArea) {
                if (_opsArea.indexOf(h.ops_area) === -1) {
                    _opsArea.push(h.ops_area);
                }
            }
            if (_countries) {
                if (_countries.indexOf(h.country) === -1) {
                    _countries.push(h.country);
                }
            }
            if (_brands) {
                if (_brands.indexOf(h.brand) === -1) {
                    _brands.push(h.brand);
                }
            }
            if (_ownerships) {
                if (_ownerships.indexOf(h.ownership) === -1) {
                    _ownerships.push(h.ownership);
                }
            }
            if (_perimeterGrp) {
                if (_perimeterGrp.indexOf(h.perimeter_grp) === -1) {
                    _perimeterGrp.push(h.perimeter_grp);
                }
            }
            if (_perimeter5yp) {
                if (_perimeter5yp.indexOf(h.perimeter_5yp) === -1) {
                    _perimeter5yp.push(h.perimeter_5yp);
                }
            }
            if (_revAreas) {
                if (_revAreas.indexOf(h.rev_area) === -1) {
                    _revAreas.push(h.rev_area);
                }
            }
            if (_rmcFlags) {
                if (_rmcFlags.indexOf(h.rmc_flag) === -1) {
                    _rmcFlags.push(h.rmc_flag);
                }
            }
            if (_cities) {
                if (_cities.indexOf(h.city) === -1) {
                    _cities.push(h.city);
                }
            }
            if (_sources) {
                if (_sources.indexOf(h.source) === -1) {
                    _sources.push(h.source);
                }
            }
            if (_rooms) {
                if (_rooms.indexOf(h.room_count) === -1) {
                    _rooms.push(h.room_count);
                }
            }
            if (_theatres) {
                if (_theatres.indexOf(h.theatre) === -1) {
                    _theatres.push(h.theatre);
                }
            }
            if (_ccFlags) {
                if (_ccFlags.indexOf(h.cc) === -1) {
                    _ccFlags.push(h.cc);
                }
            }
            if (_focusFlags) {
                if (_focusFlags.indexOf(h.focus) === -1) {
                    _focusFlags.push(h.focus);
                }
            }
            if (_hotel_type) {
                if (_hotel_type.indexOf(h.hotel_type) === -1) {
                    _hotel_type.push(h.hotel_type);
                }
            }
            if (_market_type) {
                if (_market_type.indexOf(h.market_type) === -1) {
                    _market_type.push(h.market_type);
                }
            }
            if (_me_cluster) {
                if (_me_cluster.indexOf(h.me_cluster) === -1) {
                    _me_cluster.push(h.me_cluster);
                }
            }
            if (_prevOpsArea) {
                if (_prevOpsArea.indexOf(h.prev_ops_area) === -1) {
                    _prevOpsArea.push(h.prev_ops_area);
                }
            }
            if (_mdArea) {
                if (_mdArea.indexOf(h.md_area) === -1) {
                    _mdArea.push(h.md_area);
                }
            }
            if (_status) {
                if (_status.indexOf(h.STATUS) === -1) {
                    _status.push(h.STATUS);
                }
            }
            if (_arm) {
                if (_arm.indexOf(h.arm_zone) === -1) {
                    _arm.push(h.arm_zone);
                }
            }
            if (_drm) {
                if (_drm.indexOf(h.drm_zone) === -1) {
                    _drm.push(h.drm_zone);
                }
            }
            if (_dop) {
                if (_dop.indexOf(h.dop) === -1) {
                    _dop.push(h.dop);
                }
            }
            if (_hotelPlace) {
                if (_hotelPlace.indexOf(h.hotel_place) === -1) {
                    _hotelPlace.push(h.hotel_place);
                }
            }
            if (_rrfsm) {
                if (_rrfsm.indexOf(h.rr_fsm) === -1) {
                    _rrfsm.push(h.rr_fsm);
                }
            }
            if (_stars) {
                if (_stars.indexOf(h.stars) === -1) {
                    _stars.push(h.stars);
                }
            }
            if (_pms) {
                if (_pms.indexOf(h.pms) === -1) {
                    _pms.push(h.pms);
                }
            }
        }
        // Update dxComponents
        if (_opsArea) {
            _dxOpsArea.selectedItemKeys = _opsArea;
        }
        if (_countries) {
            _dxCountry.selectedItemKeys = _countries;
        }
        if (_brands) {
            _dxBrand.selectedItemKeys = _brands;
        }
        if (_ownerships) {
            _dxContract.selectedItemKeys = _ownerships;
        }
        if (_revAreas) {
            _dxRevArea.selectedItemKeys = _revAreas;
        }
        if (_rmcFlags) {
            _dxRmcFlag.selectedItemKeys = _rmcFlags;
        }
        if (_cities) {
            _dxCity.selectedItemKeys = _cities;
        }
        if (_sources) {
            _dxSource.selectedItemKeys = _sources;
        }
        if (_rooms) {
            _dxRoom.selectedItemKeys = _rooms;
        }
        if (_theatres) {
            _dxTheatre.selectedItemKeys = _theatres;
        }
        if (_perimeterGrp) {
            _dxPerimeterGrp.selectedItemKeys = _perimeterGrp;
        }
        if (_perimeter5yp) {
            _dxPerimeter5yp.selectedItemKeys = _perimeter5yp;
        }
        if (_ccFlags) {
            _dxCCFlag.selectedItemKeys = _ccFlags;
        }
        if (_focusFlags) {
            _dxFocusFlag.selectedItemKeys = _focusFlags;
        }
        if (_hotel_type) {
            _dxHtlType.selectedItemKeys = _hotel_type;
        }
        if (_market_type) {
            _dxMarketType.selectedItemKeys = _market_type;
        }
        if (_me_cluster) {
            _dxMECluster.selectedItemKeys = _me_cluster;
        }
        if (_prevOpsArea) {
            _dxPrevOpsArea.selectedItemKeys = _prevOpsArea;
        }
        if (_mdArea) {
            _dxMdArea.selectedItemKeys = _mdArea;
        }
        if (_status) {
            _dxStatus.selectedItemKeys = _status;
        }
        if (_arm) {
            _dxArm.selectedItemKeys = _arm;
        }
        if (_drm) {
            _dxDrm.selectedItemKeys = _drm;
        }
        if (_dop) {
            _dxDop.selectedItemKeys = _dop;
        }
        if (_hotelPlace) {
            _dxHotelPlace.selectedItemKeys = _hotelPlace;
        }
        if (_rrfsm) {
            _dxRRFsm.selectedItemKeys = _rrfsm;
        }
        if (_stars) {
            _dxStars.selectedItemKeys = _stars;
        }
        if (_pms) {
            _dxPms.selectedItemKeys = _pms;
        }
        // Change to true a reminder to update entities outside filters
        this.updateEntities = true;
    }

    /**
     * Updates entityService various selected items
     */
    private updateEntityServiceSelecteds(): void {
        const hotels = this.entityService
            .getEntities()
            .filter((entity) =>
                this.entityMapFilters.get('hotel').selectedKeys.includes(entity.id)
            );
        const _opsArea = [];
        const _prevOpsArea = [];
        const _mdArea = [];
        const _countries = [];
        const _brands = [];
        const _ownerships = [];
        const _perimeterGrp = [];
        const _perimeter5yp = [];
        const _revAreas = [];
        const _rmcFlags = [];
        const _cities = [];
        const _sources = [];
        const _rooms = [];
        const _theatres = [];
        const _ccFlags = [];
        const _focusFlags = [];
        const _status = [];
        const _arm = [];
        const _drm = [];
        const _dop = [];
        const _hotelPlace = [];
        const _rrfsm = [];
        const _stars = [];
        const _pms = [];
        // Fill arrays
        for (const h of hotels) {
            if (_opsArea) {
                if (_opsArea.indexOf(h.parent_name) === -1) {
                    _opsArea.push(h.parent_name);
                }
            }
            if (_prevOpsArea) {
                if (_prevOpsArea.indexOf(h.prev_ops_area) === -1) {
                    _prevOpsArea.push(h.prev_ops_area);
                }
            }
            if (_countries) {
                if (_countries.indexOf(h.country) === -1) {
                    _countries.push(h.country);
                }
            }
            if (_brands) {
                if (_brands.indexOf(h.brand) === -1) {
                    _brands.push(h.brand);
                }
            }
            if (_ownerships) {
                if (_ownerships.indexOf(h.ownership) === -1) {
                    _ownerships.push(h.ownership);
                }
            }
            if (_perimeterGrp) {
                if (_perimeterGrp.indexOf(h.perimeter_grp) === -1) {
                    _perimeterGrp.push(h.perimeter_grp);
                }
            }
            if (_perimeter5yp) {
                if (_perimeter5yp.indexOf(h.perimeter_5yp) === -1) {
                    _perimeter5yp.push(h.perimeter_5yp);
                }
            }
            if (_revAreas) {
                if (_revAreas.indexOf(h.rev_area) === -1) {
                    _revAreas.push(h.rev_area);
                }
            }
            if (_rmcFlags) {
                if (_rmcFlags.indexOf(h.rmc_flag) === -1) {
                    _rmcFlags.push(h.rmc_flag);
                }
            }
            if (_cities) {
                if (_cities.indexOf(h.city) === -1) {
                    _cities.push(h.city);
                }
            }
            if (_sources) {
                if (_sources.indexOf(h.source) === -1) {
                    _sources.push(h.source);
                }
            }
            if (_rooms) {
                if (_rooms.indexOf(h.room_count) === -1) {
                    _rooms.push(h.room_count);
                }
            }
            if (_theatres) {
                if (_theatres.indexOf(h.theatre) === -1) {
                    _theatres.push(h.theatre);
                }
            }
            if (_ccFlags) {
                if (_ccFlags.indexOf(h.cc) === -1) {
                    _ccFlags.push(h.cc);
                }
            }
            if (_focusFlags) {
                if (_focusFlags.indexOf(h.focus) === -1) {
                    _focusFlags.push(h.focus);
                }
            }
            if (_mdArea) {
                if (_mdArea.indexOf(h.md_area) === -1) {
                    _mdArea.push(h.md_area);
                }
            }
            if (_status) {
                if (_status.indexOf(h.STATUS) === -1) {
                    _status.push(h.STATUS);
                }
            }
            if (_arm) {
                if (_arm.indexOf(h.arm_zone) === -1) {
                    _arm.push(h.arm_zone);
                }
            }
            if (_drm) {
                if (_drm.indexOf(h.drm_zone) === -1) {
                    _drm.push(h.drm_zone);
                }
            }
            if (_dop) {
                if (_dop.indexOf(h.dop) === -1) {
                    _dop.push(h.dop);
                }
            }
            if (_hotelPlace) {
                if (_hotelPlace.indexOf(h.hotel_place) === -1) {
                    _hotelPlace.push(h.hotel_place);
                }
            }
            if (_rrfsm) {
                if (_rrfsm.indexOf(h.rr_fsm) === -1) {
                    _rrfsm.push(h.rr_fsm);
                }
            }
            if (_stars) {
                if (_stars.indexOf(h.stars) === -1) {
                    _stars.push(h.stars);
                }
            }
            if (_pms) {
                if (_pms.indexOf(h.pms) === -1) {
                    _pms.push(h.pms);
                }
            }
        }
        // Update entityService selecteds
        // regions where all hotels are selected
        const fullRegions = [];
        // Obtain the regions where between 1 and all hotels selected
        const notFullRegions = [];
        _opsArea.forEach((area) => {
            const areaEntities = this.entityService.getEntityIdByOpsArea(area);
            const selectedEntities = this.entityMapFilters.get('hotel').selectedKeys;
            if (areaEntities.every((el) => selectedEntities.includes(el))) {
                fullRegions.push(area);
            } else {
                notFullRegions.push(area);
            }
        });
        this.entityService.selectedRegion = fullRegions;
        this.entityService.selectedRegionNotFully = notFullRegions;
        this.entityService.selectedCountry = _countries;
        this.entityService.selectedBrand = _brands;
        this.entityService.selectedContract = _ownerships;
        this.entityService.selectedRevArea = _revAreas;
        this.entityService.selectedRmcFlag = _rmcFlags;
        this.entityService.selectedCities = _cities;
        this.entityService.selectedSource = _sources;
        this.entityService.selectedRooms = _rooms;
        this.entityService.selectedTheatre = _theatres;
        this.entityService.selectedPerimeterGrp = _perimeterGrp;
        this.entityService.selectedPerimeter5yp = _perimeter5yp;
        this.entityService.selectedCCFlag = _ccFlags;
        this.entityService.selectedFocusFlag = _focusFlags;
        this.entityService.selectedPrevOpsArea = _prevOpsArea;
        this.entityService.selectedMdArea = _mdArea;
        this.entityService.selectedStatus = _status;
        this.entityService.selectedArmZone = _arm;
        this.entityService.selectedDrmZone = _drm;
        this.entityService.selectedDop = _dop;
        this.entityService.selectedHotelPlace = _hotelPlace;
        this.entityService.selectedRRFsm = _rrfsm;
        this.entityService.selectedStars = _stars;
        this.entityService.selectedPms = _pms;
        this.entityService.updateSelectedEntityLabel();
    }
    public onShowClick(): void {
        this.showHotelList = true;
        let _selectedHotels = this.entityMapFilters
            .get('hotel')
            .selectedKeys.map((htl) => {
                return {
                    htlcd: htl,
                    hotelName: this.entityService.getEntity(htl)?.name,
                    htlid: this.entityService.getEntity(htl)?.htl_id,
                    keys: this.entityService.getEntity(htl)?.tot_keys
                };
            });
        _selectedHotels = _selectedHotels.filter((htl) =>
            this.allowedHtlIds.includes(
                htl.htlid
                    .split(',')
                    .map(Number)
                    .find((id) => id)
            )
        );
        this.generateDataTable(_selectedHotels);
    }
    public onHotelClose(): void {
        this.showHotelList = false;
    }
    private generateDataTable(
        hotelListData: { htlcd: string; hotelName: string; keys: number }[]
    ): void {
        if (this.hotelListDataTable) {
            this.hotelListDataTable.destroy();
            $('#hotelListDataTable').empty();
        }
        this.hotelListDataTable = $('#hotelListDataTable').DataTable({
            layout: {
                topEnd: 'buttons'
            },
            buttons: [
                {
                    extend: 'excel',
                    exportOptions: { orthogonal: 'export' }
                }
            ],
            autoWidth: false,
            paging: true,
            pageLength: 20,
            ordering: false,
            searching: true,
            lengthChange: false,
            info: false,
            sScrollX: true,
            scrollXInner: true,
            scrollCollapse: true,
            columns: [
                { data: 'htlcd', title: 'Code' },
                { data: 'hotelName', title: 'Name' },
                { data: 'keys', title: 'Keys' }
            ],
            columnDefs: [
                {
                    targets: [0, 1, 2],
                    defaultContent: ''
                }
            ]
        });
        // Print
        setTimeout(() => {
            this.hotelListDataTable.clear().rows.add(hotelListData).draw();
        }, 0);
    }
    public checkAllowed(htl_id: string): boolean {
        const _htl_id: number[] = htl_id.split(',').map(Number);
        return this.allowedHtlIds
            ? checkAllowedHtl(
                  this.dashboardService.allowedHtlIds,
                  _htl_id,
                  this.dashboardService.id
              )
            : true;
    }
}
