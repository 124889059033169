import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DashboardService } from 'app/core/services/dashboard.service';
import { ServerService } from 'app/core/services/server.service';

import { abortUnsubscribe } from 'app/shared/utils/shared-methods.utils';
import { Subject } from 'rxjs';
import { ProgressbarComponent } from 'ngx-bootstrap/progressbar';
import { UserService } from 'app/core/services/user.service';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-dashboard-data-controls',
    templateUrl: './dashboard-datacontrol.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./dashboard-datacontrol.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, NgIf]
})
export class DashboardDatacontrolComponent implements AfterViewInit {
    public id: number[] = [];
    public description: string;
    public progress: number;
    //public status: string;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    @ViewChild('elt') el: ElementRef;
    @ViewChild('progressbar') ProgressbarComponent: ProgressbarComponent;

    @Output() progressDataUpdateEvent: EventEmitter<unknown[]> = new EventEmitter();

    @Input() public outsideCloseDisabled: boolean;
    @Input() public progressData = [];
    loading = true;
    show = false;
    constructor(
        private _eref: ElementRef,
        public dashboardService: DashboardService,
        private serverService: ServerService,
        private userService: UserService
    ) {}
    ngAfterViewInit(): void {
        $(this.el.nativeElement)
            .find('.dropdown-close')
            .click(() => {
                this.show = false;
            });
    }

    toggle(): void {
        this.show = !this.show;
    }
    @HostListener('document:click', ['$event'])
    clickedOutside($event): void {
        if (
            !this.outsideCloseDisabled &&
            !$event.DXCLICK_FIRED &&
            (!this._eref.nativeElement.contains($event.target) ||
                $event.target.className == 'btn btn-primary dropdown-toggle')
        ) {
            this.show = false;
        }
    }

    public callServer(): void {
        if (this.progressData.length === 0) {
            // to avoid to fetch data each time we open the tooltip
            this.refresh();
        }
    }

    public refresh(): void {
        this.loading = true;
        this.serverService.post(
            'section/getProgress',
            {
                ds_id: this.userService.user.dashboards.find(
                    (d) => d.id == this.dashboardService.id
                )?.fkey_sys_pi_import_tables,
                entities: this.dashboardService.selectedHtlId
            },
            (data: any[]) => {
                if (data && data.length) {
                    console.log(data);
                    this.progressData = data;
                    this.progressDataUpdateEvent.emit(this.progressData);
                }
                this.loading = false;
            },
            (_error) => {
                this.loading = false;
            },
            this.ngUnsubscribe,
            true
        );
    }

    public ngOnDestroy(): void {
        abortUnsubscribe(this.ngUnsubscribe, true);
    }
}
