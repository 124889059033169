import { Injectable } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { EntityService } from './entity.service';
import { GlobalService } from './global.service';
import { ServerService } from './server.service';
import { SectionHtmlData } from '../../shared/utils/shared-constants.utils';
import { saveAs } from 'file-saver';
import { _widgetGlobalCss } from '../components/widgets/widgets.pdf.css';
/**
 * Service used to create reports using pdfMake
 */
@Injectable({ providedIn: 'root' })
export class wkHtmlToPdfService {
    private dashboardFilters: string[];
    constructor(
        private globalService: GlobalService,
        private dashboardservice: DashboardService,
        private entityservice: EntityService,
        private serverservice: ServerService
    ) {}

    /**
     * Public method to create the dashboad pdf, this report contains:
     * - Cover page.
     * - One page for any node (section) obtained after dashboardCallback.
     * - Appendix.
     *
     * Each section page will include:
     * - The title of the section.
     * - The filters used that are not entities.
     * - The charts/tables of the section.
     * The appendix will show the entities used to fill the report.
     * @param dashboardCallback
     */
    public createDashboardPdf(dashboardCallback: () => any): void {
        this.globalService.loadingText('Generating PDF');
        const _fileName = this.dashboardservice.title.replace(/ /g, '_');

        setTimeout(() => {
            // Required to display loading popup

            dashboardCallback().then(
                (sectionsHtmlData: SectionHtmlData[]) => {
                    const _globalCss = '<style>' + _widgetGlobalCss + '</style>';
                    const _htmlToPrint =
                        _globalCss + sectionsHtmlData.map((m) => m.html).join('');

                    //console.log('HTMLTOPRINT', _htmlToPrint);
                    this.serverservice
                        .wkhtmltopdf(_htmlToPrint, 'BLA', 0, 'TEST')
                        .subscribe(
                            (data) => {
                                this.globalService.loading(false);
                                saveAs(data, _fileName + '.pdf');
                            },
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            (err) => {
                                alert('Problem while downloading the file');
                            }
                        );
                },
                (error) => {
                    console.error(error); // TODO
                }
            );
        }, 0);
    }

    public async _getBase64(path: string): Promise<any> {
        const _res = await fetch(path);
        const _blob = await _res.blob();
        return new Promise((resolve, reject) => {
            const _reader = new FileReader();
            _reader.addEventListener(
                'load',
                () => {
                    resolve(_reader.result);
                },
                false
            );
            _reader.onerror = (): void => {
                reject(this);
            };
            _reader.readAsDataURL(_blob);
        });
    }

    /**
     * In case there are report-level filters that should be shown on every page.
     * Pass array of strings of what filters you want displayed. Call from the
     * update component of the dashboard component.
     * @param dashboardFilters
     */
    public setDashboardFilters(dashboardFilters: string[]): void {
        this.dashboardFilters = dashboardFilters;
    }
    /**
     * Call in NgOnDestroy.
     * Sets dashboardFilters variable to undefined when destroying component that set filters
     */
    public resetDashboardFilters(): void {
        this.dashboardFilters = undefined;
    }
}
