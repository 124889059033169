import { sortArray } from 'app/shared/utils/shared-methods.utils';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
/**
 * This class it's the content of every dxList or dxTreeList of dashboard-filters.component
 * Four 'type' availables:
 * - 0: Tree
 * - 1: List - all
 * - 2: List - multiple
 * - 3: List - single
 * Also, it's possible to set how it's dashboardService it's going to use them when update the selection.
 * Three 'useOnSelectionAs' availables:
 * - 0: Don't add to selectedFilters.
 * - 1 (default): Add to selectedFilters the param 'id' as key with an array of idKey as value.
 * - 2 (Only available for type 0): Same case as default but use textKey instead of idKey.
 *
 * Also, when using a dashboardFilter type tree we can avoid to set undesired values to selectedFilters adding
 * the param 'extra' on this kind of values at treeSource.
 */
export class DashboardFilter {
    public treeSource: any[]; // For type 0 (tree)
    public listSource: DataSource; // For type 1, 2 or 3 (list)
    public typeList: string;
    public selectedKeys: any[];
    public selectedKeysBackup: any[];
    public searchExpr: string[]; // For type 1, 2 or 3 (list)
    public enabled: boolean;
    public useOnSelectionAs: number;
    public selectionChanged: boolean;
    public required = true; // All the filters are required by default
    public customColSize: string;
    public disabled: boolean;
    public hidden = false; // All the filters are visible by default
    public headerF: Function; // eslint-disable-line
    public headerIco: string;
    public tooltip: string;
    public maxHeigth: string;
    constructor(
        public type: number,
        public id: string,
        public textHeader: string,
        public idKey: string,
        public textKey: string,
        array: any[],
        public className?: string,
        public onFilterChange?: Function, // eslint-disable-line
        selectionAs?: number,
        sortSelector?: string,
        sortAscending?: boolean,
        maxHeigth?: string
    ) {
        sortArray(array, idKey);
        if (type) {
            switch (type) {
                case 1:
                    this.typeList = 'all';
                    break;
                case 2:
                    this.typeList = 'multiple';
                    break;
                case 3:
                    this.typeList = 'single';
                    break;
            }
            this.setListSource(array, sortSelector, sortAscending);
        } else {
            this.treeSource = array;
        }
        this.selectedKeys = [];
        this.selectedKeysBackup = [];
        this.useOnSelectionAs = selectionAs === undefined ? 1 : selectionAs;
        if (!this.className) {
            this.className = 'filter-column-medium';
        }
        this.maxHeigth = maxHeigth;
    }
    public setListSource(
        array: any[],
        sortSelector: string,
        sortAscending: boolean
    ): void {
        const _store = new ArrayStore({ key: this.idKey, data: array });
        this.listSource = new DataSource({ store: _store });
        if (sortSelector) {
            if (sortAscending) {
                this.listSource.sort({ selector: sortSelector, desc: false });
            } else {
                this.listSource.sort({ selector: sortSelector, desc: true });
            }
        }
    }
    public saveKeysBackup(keys: any[]): void {
        this.selectedKeysBackup = [...new Set(keys)];
    }
    public selectAsBackup(): void {
        this.selectedKeys = [...new Set(this.selectedKeysBackup)];
    }
    /**
     * Use this selection only before set the DashboardFilter on a dxComponent.
     * If the dxComponent it's already defined, change the selection using that component instead.
     */
    public defaultSelection(keys?: any[]): void {
        this.saveKeysBackup(keys);
        this.selectAsBackup();
    }
}
