import { Routes } from '@angular/router';
export const rhgRoutes: Routes = [
    {
        path: 'dashboard/index',
        loadComponent: () =>
            import('app/clients/rhg/dashboards/index/index.component').then(
                (m) => m.IndexComponent
            )
    },
    {
        path: 'dashboard/docs',
        loadComponent: (): any =>
            import('app/clients/rhg/dashboards/docs/docs.component').then(
                (m) => m.DocsComponent
            )
    },
    {
        path: 'dashboard/radisson-academy',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/radisson-academy/radisson-academy.component'
            ).then((m) => m.RadissonAcademyComponent)
    },
    {
        path: 'dashboard/mobile-app-docs',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/mobile-app-docs/mobile-app-docs.component'
            ).then((m) => m.MobileAppDocsComponent)
    },
    // Revops dashboards
    {
        path: 'dashboard/pace-report',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/pace-report/pace-report.component'
            ).then((m) => m.PaceReportComponent)
    },
    {
        path: 'dashboard/rhg-marketshare',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/rhg-marketshare/rhg-marketshare.component'
            ).then((m) => m.RhgMarketshareComponent)
    },
    {
        path: 'dashboard/forecast-accuracy',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/forecast-accuracy/forecast-accuracy.component'
            ).then((m) => m.ForecastAccuracyComponent)
    },
    {
        path: 'dashboard/room-type',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/room-type/room-type.component'
            ).then((m) => m.RoomTypeComponent)
    },
    {
        path: 'dashboard/system-contribution2',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/system-contribution2/system-contribution2.component'
            ).then((m) => m.SystemContribution2Component)
    },
    {
        path: 'dashboard/system-contribution-models',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/system-contribution-models/system-contribution-models.component'
            ).then((m) => m.SystemContributionModelsComponent)
    },
    {
        path: 'dashboard/channel-mix',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/channel-mix/channel-mix.component'
            ).then((m) => m.ChannelMixComponent)
    },
    {
        path: 'dashboard/segment-mix',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/segment-mix/segment-mix.component'
            ).then((m) => m.SegmentMixComponent)
    },
    {
        path: 'dashboard/cost-acquisition',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/cost-acquisition/cost-acquisition.component'
            ).then((m) => m.CostAcquisitionComponent)
    },
    {
        path: 'dashboard/fc-evo',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/forecast-evolution/forecast-evolution.component'
            ).then((m) => m.FcEvoComponent)
    },
    {
        path: 'dashboard/ota',
        loadComponent: (): any =>
            import('app/clients/rhg/dashboards/revops/ota/ota.component').then(
                (m) => m.OtaComponent
            )
    },

    {
        path: 'dashboard/ota-v2',
        loadComponent: (): any =>
            import('app/clients/rhg/dashboards/revops/ota_v2/ota_v2.component').then(
                (m) => m.OtaV2Component
            )
    },
    {
        path: 'dashboard/pickup',
        loadComponent: (): any =>
            import('app/clients/rhg/dashboards/revops/pickup/pickup.component').then(
                (m) => m.PickupComponent
            )
    },
    {
        path: 'dashboard/agency-360',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/agency-360/agency-360.component'
            ).then((m) => m.Agency360Component)
    },
    {
        path: 'dashboard/brand-com',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/brand-com/brand-com.component'
            ).then((m) => m.BrandComComponent)
    },
    {
        path: 'dashboard/pulse-pivot',
        loadComponent: (): any =>
            import('app/clients/rhg/dashboards/revops/pivot/pulse-pivot.page').then(
                (m) => m.PulsePivotPage
            )
    },
    {
        path: 'dashboard/campaign-promotion-overview',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/campaign-promotion/campaign-promotion.component'
            ).then((m) => m.CampaignPromotionComponent)
    },
    {
        path: 'dashboard/pulse-pivot-v2',
        loadComponent: (): any =>
            import('app/clients/rhg/dashboards/revops/pivot2/pulse-pivot.page').then(
                (m) => m.PulsePivotPage
            )
    },
    {
        path: 'dashboard/corporate-pickup',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/corporate-pickup/corporate-pickup.component'
            ).then((m) => m.CorporatePickupComponent)
    },
    {
        path: 'dashboard/events-calendar',
        loadComponent: (): any =>
            import('app/clients/rhg/dashboards/events/events.component').then(
                (m) => m.EventsDashboardComponent
            )
    },
    // Distribution dashboards
    {
        path: 'dashboard/demand-360',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/distribution/demand-360/demand-360.component'
            ).then((m) => m.Demand360Component)
    },
    // S&M dashboards
    {
        path: 'dashboard/sales-production',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/sales-production/sales-production.component'
            ).then((m) => m.SalesProductionComponent)
    },
    {
        path: 'dashboard/sp-executive',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/sp-executive/sp-executive.component'
            ).then((m) => m.SPExecutiveComponent)
    },
    {
        path: 'dashboard/me-intel',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-intel/me-intel.component'
            ).then((m) => m.MEIntelComponent)
    },
    {
        path: 'dashboard/me-ent',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-ent/me-ent.component'
            ).then((m) => m.MEEntComponent)
    },
    {
        path: 'dashboard/me-leads',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-leads/me-leads.component'
            ).then((m) => m.MELeadsComponent)
    },
    {
        path: 'dashboard/me-space',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-space/me-space.component'
            ).then((m) => m.MESpaceComponent)
    },
    {
        path: 'dashboard/me-connectivity',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-connectivity/me-connectivity.component'
            ).then((m) => m.MEConnectivityComponent)
    },
    {
        path: 'dashboard/me-cxl',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-cxl/me-cxl.component'
            ).then((m) => m.MECxlComponent)
    },
    {
        path: 'dashboard/account-portfolio',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/account-portfolio/account-portfolio.component'
            ).then((m) => m.AccountPortfolioComponent)
    },
    {
        path: 'dashboard/account-manager',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/account-manager/account-manager.component'
            ).then((m) => m.AccountManagerComponent)
    },
    {
        path: 'dashboard/rate-code-production',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/rate-code-production/rate-code-production.component'
            ).then((m) => m.RateCodeComponent)
    },
    // Operations dashboards
    {
        path: 'dashboard/operational-report',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/finance/operational-report/operational-report.component'
            ).then((m) => m.OperationalReportComponent)
    },
    {
        path: 'dashboard/operational-report-lhg',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/finance/operational-report-lhg/operational-report-lhg.component'
            ).then((m) => m.OperationalReportLhgComponent)
    },
    {
        path: 'dashboard/quality-report',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/operations/quality-report/quality-report.component'
            ).then((m) => m.QualityReportComponent)
    },
    {
        path: 'dashboard/quality-review-pro',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/operations/quality-review-pro/quality-review-pro.component'
            ).then((m) => m.QualityReviewProComponent)
    },
    {
        path: 'dashboard/loyalty',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/operations/loyalty/loyalty.component'
            ).then((m) => m.LoyaltyComponent)
    },
    // Presentations dashboards
    {
        path: 'dashboard/revco_v2',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/presentations/revco2/revco2.component'
            ).then((m) => m.RevCo2Component)
    },
    {
        path: 'dashboard/area_performance',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/presentations/area-performance/area-performance.component'
            ).then((m) => m.AreaPerformanceComponent)
    },
    {
        path: 'dashboard/revgen',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/presentations/revgen/revgen.component'
            ).then((m) => m.RevGenComponent)
    },

    // Data Status Dashboard
    {
        path: 'dashboard/import-control',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/data-control/import-control/import-control.component'
            ).then((m) => m.ImportControlComponent)
    },
    // if environment is not production, show the test dashboards
    /* ...(environment.name != 'prod'
        ? [ */
    {
        path: 'dashboard/productivity-home-page',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-home-page/prod-home-page.component'
            ).then((m) => m.ProductivityHomePageComponent)
    },
    {
        path: 'dashboard/productivity-dashboard',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-dashboard/prod-dashboard.component'
            ).then((m) => m.ProductivityDashboardComponent)
    },
    {
        path: 'dashboard/productivity-accuracy',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-accuracy/prod-accuracy.component'
            ).then((m) => m.ProductivityAccuracyComponent)
    },
    {
        path: 'dashboard/productivity-benchmark',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-benchmark/prod-benchmark.component'
            ).then((m) => m.ProductivityBenchmarkComponent)
    },
    {
        path: 'dashboard/productivity-perspective',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-management-perspective/prod-management-perspective.component'
            ).then((m) => m.ProductivityManagementPerspective)
    },
    {
        path: 'dashboard/productivity-insights',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-insights/prod-insights.module'
            ).then((m) => m.ProductivityInsightsModule)
    },
    {
        path: 'dashboard/productivity-cockpit',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-cockpit/prod-cockpit.component'
            ).then((m) => m.ProductivityCockpitComponent)
    },
    {
        path: 'dashboard/productivity-cockpit-v2',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-cockpit-v2/prod-cockpit-v2.component'
            ).then((m) => m.ProductivityCockpitComponentV2)
    },
    {
        path: 'dashboard/productivity-targets',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-targets/prod-targets.component'
            ).then((m) => m.ProductivityTargetsComponent)
    },
    {
        path: 'dashboard/productivity-admin',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/admin/prod-admin.component'
            ).then((m) => m.ProductivityAdminComponent)
    },
    {
        path: 'dashboard/productivity-global-mapping',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/productivity-global-mapping/productivity-global-mapping.component'
            ).then((m) => m.ProductivityGlobalMappingDashboardComponent)
    },
    {
        path: 'dashboard/productivity-settings',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-settings/prod-settings.component'
            ).then((m) => m.ProductivityCockpitSettingsDashboardComponent)
    },
    {
        path: 'dashboard/productivity-targets-v2',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-cockpit-targets/prod-cockpit-targets.module'
            ).then((m) => m.ProductivityCockpitTargetsModule)
    },
    {
        path: 'dashboard/orma-dashboard',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/orma/orma-dashboard/orma-dashboard.component'
            ).then((m) => m.OrmaDashboardComponent)
    },
    // Displacement Tool
    {
        path: 'dashboard/displacement-tool',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/displacement/displacement-tool/displacement-tool.component'
            ).then((m) => m.DisplacementToolComponent)
    },
    {
        path: 'dashboard/displacement-config',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/displacement/displacement-config/displacement-config.component'
            ).then((m) => m.DisplacementConfigComponent)
    },
    {
        path: 'dashboard/displacement-pricing',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/displacement/displacement-pricing/displacement-pricing.component'
            ).then((m) => m.DisplacementPricingComponent)
    },
    {
        path: 'dashboard/one-pager',
        loadComponent: (): any =>
            import(
                'app/clients/rhg/dashboards/score-cards/one-pager/one-pager.component'
            ).then((m) => m.OnePagerComponent)
    }
];
