import { Component, ViewEncapsulation } from '@angular/core';
import { UnwrapTagDirective } from '../directives/unwrap-tag.directive';

@Component({
    selector: 'app-scroll-top',
    templateUrl: './scroll-top.component.html',
    encapsulation: ViewEncapsulation.None,
    imports: [UnwrapTagDirective]
})
export class ScrollTopComponent {
    constructor() {}
}
