<div class="btn-group" role="group" style="background-color: #7e94ac;">
    <button type="button" class="btn  btn-nav" (click)="nav(-1)" style="height: 39px;
    border: 1px solid #7e94ac;    padding-top: 6px;
    color: white;border-right: none;"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <div class="btn-group" role="group" ngbDropdown>
        <button mat-button class="btn btn-secondary btn-dropdown"
            style="border: 1px solid #7e94ac;border-left: none;border-right: none;background-color: #7e94ac; color: white;width: 70px;height: 39px;font-size: 15px;min-width: 80px;" ngbDropdownToggle>
            <span>{{ selectedOption?.label }}</span>
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
            <button class="dropdown-item" *ngFor="let item of options" (click)="select(item)">
                {{ item.label }}
            </button>
        </div>
    </div>
    <button type="button" class="btn btn-nav" (click)="nav(1)" style="height: 39px;
    border: 1px solid #7e94ac;    padding-top: 6px;
    color: white;border-left: none;"><mat-icon>keyboard_arrow_right</mat-icon></button>
</div>