<div  class="p-dropdown" [ngClass]="{ 'p-dropdown--open': show === true }" #elt>
    <span class="p-dropdown-toggle" (click)="toggle(); callServer()">
        <ng-content select=".button"></ng-content>
    </span>
    <div class="p-dropdown-wrapper">
        <div class="p-dropdown-inner">
            <div class="p-dropdown-inner-container">
                <div>
                    <div class="m--pull-right">
                        <span id="refreshButtonToggle" class="close-ico" (click)="refresh()" title="refresh">
                            <i class="fa flaticon-refresh"></i>
                        </span>
                        <span id="optionsButtonToggle" class="close-ico" (click)="toggle()" title="close">
                            <i class="la la-4x la-remove"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="loading">
                    <span>
                        <div class="m-loader m-loader--brand"></div>
                    </span>
                </div>
                <ng-content select=".dropdown-content" class="text-center">
                </ng-content>
            </div>
            <span class="p-dropdown-arrow"></span>
        </div>
    </div>
</div>