import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { findIndex } from 'lodash';
import { NgFor } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'toolbar-dropdown-nav-year',
    templateUrl: './toolbar-dropdown-nav-year.component.html',
    styleUrls: ['./toolbar-dropdown-nav-year.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [
        MatIcon,
        NgbDropdown,
        MatButton,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgFor,
    ],
})
export class ToolbarDropdownNavYearComponent {

    @Input() yearId: number = new Date().getFullYear();

    @Input() options: DropDownOption[] = [];

    @Input() selectedOption: DropDownOption | null | undefined;

    @Output() valueChange = new EventEmitter<DropDownOption>();

    select(selectedOption: DropDownOption) {
        this.selectedOption = selectedOption;
        this.valueChange.emit(selectedOption);
    }

    nav(value: number) {
        if (this.options.length === 0) return;
        if (!this.selectedOption) return this.select(this.options[0]);
        const currentIndex = findIndex(this.options, { id: this.selectedOption.id }) + value;
        if (currentIndex === -1) return this.select(this.options[0]);
        if (currentIndex > this.options.length || currentIndex < 0) return;
        this.select(this.options[currentIndex]);
    }
}
export interface DropDownOption {
    id: string | number;
    label: string;
}