<!-- begin:: Page -->
<router-outlet></router-outlet>
<!-- end:: Page -->
<entity-sidebar></entity-sidebar>
<app-scroll-top></app-scroll-top>
<!--begin::Base Scripts -->
<!--end::Base Scripts -->
<!--begin::Page Vendors -->
<!--end::Page Vendors -->
<!--begin::Page Snippets -->
<!--end::Page Snippets -->
<div id="m_quick_sidebar_toggle"></div>
<alert></alert>
<loading></loading>
<div
    bsModal
    #alertModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-alert-name"
>
    <div class="modal-dialog modal-md modal-alert" [hidden]="alertHidden">
        <div
            class="modal-content"
            [ngClass]="{
                'border-alert':
                    alertData?.author === 'ALERT' && alertData?.isWebinar === 0,
                'border-info': alertData?.isWebinar === 1 || alertData?.author === 'INFO'
            }"
        >
            <div class="modal-header" style="display: flex">
                <h4 id="dialog-alert-name" class="modal-title pull-left">
                    <i
                        class="fa"
                        [ngClass]="{
                            'fa-exclamation-triangle titleAlert':
                                alertData?.author === 'ALERT' &&
                                alertData?.isWebinar === 0,
                            'fa-info-circle titlInfo':
                                alertData?.isWebinar === 1 || alertData?.author === 'INFO'
                        }"
                    ></i
                    >{{ alertData?.title }}
                </h4>
                <button
                    type="button"
                    class="close btn-close text-reset"
                    aria-label="Close"
                    (click)="hideAlertModal()"
                >
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div
                class="modal-body"
                [innerHTML]="alertData?.description"
                style="text-align: center"
            ></div>
            <div style="border-top: 1px solid #dee2e6; text-align: center; padding: 15px">
                <ng-container *ngIf="alertData?.isWebinar === 1; else acknowledgeOnly">
                    <!-- <button
                        type="button"
                        (click)="sendWebinarInvite(userService.user, '1')"
                        class="btn btn-info text-white me-2"
                    >
                        THU 09/01/2025 10:00 - 10:30
                    </button> -->
                    <button
                        type="button"
                        (click)="sendWebinarInvite(userService.user, '2')"
                        class="btn btn-info text-white me-2"
                    >
                        THU 16/01/2025 10:00 - 10:30
                    </button>
                    <button
                        type="button"
                        (click)="onAlertAcknowledge()"
                        class="btn text-white"
                        [ngClass]="{
                            'btn-danger': alertData?.author === 'ALERT',
                            'btn-info': alertData?.author === 'INFO'
                        }"
                    >
                        Acknowledge
                    </button>
                </ng-container>
                <ng-template #acknowledgeOnly>
                    <button
                        type="button"
                        (click)="onAlertAcknowledge()"
                        class="btn text-white"
                        [ngClass]="{
                            'btn-danger': alertData?.author === 'ALERT',
                            'btn-info': alertData?.author === 'INFO'
                        }"
                    >
                        Acknowledge
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
</div>
